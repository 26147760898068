import React from 'react'

const EverythingCardComp = ({ title, desc, ...props }: any) => {
    return (
        <>
            <div className=" p-16 bg-white border border-gray-200 rounded-2xl shadow-sm dark:bg-gray-800 dark:border-gray-700 mb-16 md:w-[28%]">
                <h5 className="mb-10 text-2xl font-bold tracking-tight text-[#616161] dark:text-primary"> {title}</h5>
                <p className="mb-3 text-base font-normal text-[#475569] dark:text-white">{desc}</p>
            </div>
        </>
    )
}

export default EverythingCardComp