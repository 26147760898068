import React, {useState} from 'react';
import {RxCross2} from 'react-icons/rx';
const AddBar = () => {
  const [isVisible, setIsVisible] = useState(true);

  if (!isVisible) return null;

  return (
    <div className="flex items-center justify-between bg-[#FFC90D] px-4 py-10 text-sm font-medium text-black">
      <div></div>
      <p>
        Start with <strong>10GB</strong> of Secure Storage Space.{' '}
        <a href="#" className="font-semibold underline">
          Get Started Now!
        </a>
      </p>
      <button onClick={() => setIsVisible(false)} className="mr-10">
        <RxCross2 />
      </button>
    </div>
  );
};

export default AddBar;
