import { Navbar } from '@common/ui/navigation/navbar/navbar'
import React from 'react'
import AddBar from '../AddBar'
import NewFooter from '@common/ui/new-footer/NewFooter'
import ContactCard from '@common/ui/contact-card/ContactCard'
import EverythingCardComp from './components/EverythingCardComp'

const SecureSharing = () => {
    return (
        <>
            <div className="container mx-auto">
                <Navbar
                    color="transparent"
                    className="flex-shrink-0"
                    menuPosition="homepage-navbar"
                />
            </div>
            <AddBar />
            <TeamCollaborationPage />
            <NewFooter />
        </>
    )
}

export default SecureSharing

function TeamCollaborationPage() {
    const everyThingData = [
        {
            title: 'Password Protection',
            desc: 'Restrict access to only those with the correct password.'
        },
        {
            title: 'Instant Access Notifications',
            desc: 'Get alerts when someone views or downloads your files.'
        },
        {
            title: 'Expiring Links',
            desc: 'Set automatic expiration dates to limit file access.'
        },
        {
            title: 'One-Click Access Revocation',
            desc: 'Remove access anytime, even after sending.'
        },
    ]
    return (
        <>

            <HeroSection />
            <div className="container mx-auto">
                <div className="grid grid-col-1 justify-center">
                    <h2 className="text-gray-900 text-start text-5xl font-bold mt-90">
                        Stay in Control with   <span className="text-primary">MyDocUploads</span>
                    </h2>
                    <p className='text-base font-normal text-center max-w-4xl mt-16'>Designed for growing businesses and distributed teams, MyDocUploads ensures secure, scalable, and hassle-free
                        collaboration, without expensive IT setup.</p>
                </div>
                <div className="md:flex md:flex-wrap justify-center gap-22 mt-52" >
                    {everyThingData.map((data, index) => (
                        <EverythingCardComp title={data.title} desc={data.desc} key={index} />
                    ))}
                </div>

                <div className="grid lg:grid-cols-2 gap-20 lg:mt-32 items-center">
                    <div className='mt-32'>
                        <h2 className="text-start dark:text-white text-5xl font-bold mt-20 lg:mt-60">
                            <span className="text-primary">Secure & Private File </span> Sharing with MyDocUploads
                        </h2>
                        <p className='text-base font-normal text-[#303030B2] dark:text-white tracking-tighter mt-32'>Email attachments expose your files to multiple parties, your email provider, the recipient’s provider, and anyone with access to their inbox. Once an attachment is sent, you have no control over who sees it or for how long.</p>
                    </div>
                    <div className='mt-32 flex justify-center'>
                        <img src="./public/images/solutions/secure-1.svg" alt="" />
                    </div>
                </div>
                <div className="grid lg:grid-cols-2 gap-20 lg:mt-32 items-center">
                    <div className='mt-32 flex justify-center'>
                        <img src="./public/images/solutions/secure-1.svg" alt="" />
                    </div>
                    <div className='mt-32'>
                        <h2 className="text-start dark:text-white text-5xl font-bold mt-20 lg:mt-60">
                            <span className="text-primary">Fast, Easy & Hassle-Free </span> Document Sharing
                        </h2>
                        <p className='text-base font-normal text-[#303030B2] dark:text-white tracking-tighter mt-32'>Ditch the risky email attachments. Share faster, safer, and smarter! MyDocUploads makes document sharing effortless, private, and secure</p>
                    </div>

                </div>
                <ContactCard />
            </div>


        </>
    );
}
function HeroSection() {
    return (
        <div className=" bg-[#10185D]  px-6 py-50 text-white md:pl-24">
            <div className="container mx-auto grid items-center gap-40 md:grid-cols-2">
                {/* Left Section - Text Content */}
                <div className="text-center md:text-left">
                    <h1 className="text-3xl font-bold leading-tight md:text-5xl">
                        Secure sharing made simple and reliable
                    </h1>
                    <p className="text-base font-semibold text-gray-200 dark:text-white mt-32">
                        Share files confidently with end-to-end encryption, ensuring only authorized users have access. Set permissions, password-protect sensitive data, and track file activity with ease.
                    </p>
                    <div className="mt-32 flex  justify-center gap-20  md:justify-start">
                        <button className=" !bg-[#F67A3C] font-semibold text-white text-sm px-32 py-12 w-fit h-fit rounded-full">
                            {' '}
                            Get Started
                        </button>

                        <button className="text-white font-semibold text-sm px-22 py-10 w-fit h-fit rounded-full border border-white">
                            {' '}
                            Compare Plans
                        </button>
                    </div>
                </div>

                {/* Right Section - Image */}
                <div className="flex justify-center">
                    <img
                        src="./public/images/solutions/HeroSideImage.svg"
                        alt="File Management"
                        className="w-full"
                    />
                </div>
            </div>
        </div>
    );
}