import { Navbar } from '@common/ui/navigation/navbar/navbar'
import NewFooter from '@common/ui/new-footer/NewFooter'
import AddBar from '../AddBar'
import { Link } from 'react-router-dom'

const BlogDetailPage = () => {
    return (
        <>
            <div className="container mx-auto">
                <Navbar
                    color="transparent"
                    className="flex-shrink-0"
                    menuPosition="homepage-navbar"
                />
            </div>
            <AddBar />
            <BlogDetailByID />
            <NewFooter />
        </>
    )
}

export default BlogDetailPage


function BlogDetailByID() {
    const cardData = Array(3).fill({
        imgSrc: "./public/images/about-us/effortless.svg",
        title: "Noteworthy technology acquisitions 2021",
        description: "Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.",
        linkText: "Read more"
    });
    return (
        <div className='container mx-auto'>
            <div className="grid grid-col-1">
                <div>
                    <img src="./public/images/blogs/blog-1.png" className='rounded-lg' alt="" />
                    <h1 className='text-3xl font-bold space-x-1 mt-16'>Enjoy it all for free! No Extra Cost</h1>
                    <p className='text-base font-normal text-[#475569] mt-3'>By Admin • November 15, 2024</p>
                </div>
            </div>
            <hr className='mt-32' />
            <div className="grid grid-cols-12 justify-center items-center">
                <div className="lg:col-span-2"></div>
                <div className="col-span-12 md:col-span-10 lg:col-span-8 mt-52">
                    <div>
                        <p className='text-sm text-[#475569] font-normal'>Welcome to our blog details page, your gateway to in-depth captivating narratives. Dive into thought-provoking articles,and engaging content that goes beyond the surface knowledge meets curiosity. Explore, discover, and enrich your understanding as we navigate diverse topics, all tailored to ignite your intellect and keep you informed. Join us on this journey of exploration, where every click opens a new world of information, ideas, and inspiration.Embark on a journey of discovery as you delve into our blog's rich tapestry of content. From the latest trends to expert advice, we curate information that sparks curiosity and fuels your quest for knowledge.</p>
                        <img src="./public/images/blogs/blog-details-img1.png" className='rounded-lg mt-32' alt="" />
                        <p className='text-sm text-[#475569] font-normal mt-16'>"I work with Alguneb Johnl on many projects, he always toldagona exceeds my expectations with his quality work and fastestopa tope service, very smooth and simple communication."</p>
                    </div>

                    <div className='mt-32'>
                        <h1 className='text-3xl font-bold space-x-1 mt-16'>Unveiling Creative Journeys</h1>
                        <p className='text-sm text-[#475569] font-normal mt-16'>Explore the dynamic stories behind our portfolio pieces on this blog details page. Each project is a testament to creativity, innovation, and collaboration. Immerse yourself in the narratives that shaped these creations, gain insights into our design philosophy, and discover the passion fueling our work.</p>
                        <div className='flex md:flex-row flex-col gap-10 mt-16'>
                            <img src="./public/images/blogs/blog-details-img2.png" className='rounded-lg mb-16 object-contain' alt="" />
                            <img src="./public/images/blogs/blog-details-img3.png" className='rounded-lg mb-16 object-contain' alt="" />
                        </div>
                        <h1 className='text-3xl font-bold space-x-1 mt-16'>Exploring Our Portfolio's Stories</h1>
                        <p className='text-sm text-[#475569] font-normal mt-16'>Discover the intricate narratives behind our portfolio pieces on our blog details page. We offer a glimpse into the creative process and inspiration driving our projects, providing valuable insights and perspectives.</p>
                    </div>
                </div>
            </div>
            <hr className='mt-52' />
            <div>
                <h1 className='text-3xl md:text-6xl font-bold text-[#383838] mt-52'>Read More <span className='text-[#3B82F6]'>Articles</span></h1>
                <div className="grid grid-cols-12 gap-20 mt-16">
                    {cardData.map((card, index) => (
                        <Link to="/pages/blog-detail" key={index} className="col-span-12 md:col-span-4 mt-16">
                            <div className=" bg-white border border-gray-200 rounded-2xl shadow-sm dark:bg-gray-800 dark:border-gray-700">
                                <img className="rounded-t-xl" src={card.imgSrc} alt={card.title} />
                                <div className="p-16">
                                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                                        {card.title}
                                    </h5>
                                    <p className="mb-3 text-sm font-normal text-[#475569] dark:text-gray-400">
                                        {card.description}
                                    </p>
                                    <div className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                        {card.linkText}
                                        <svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    )
}