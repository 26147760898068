import clsx from 'clsx';
import { LandingPageContent } from './landing-page-content';
import { Navbar } from '@common/ui/navigation/navbar/navbar';
import { Button, ButtonProps } from '@common/ui/buttons/button';
import { IconButton } from '@common/ui/buttons/icon-button';
import { KeyboardArrowDownIcon } from '@common/icons/material/KeyboardArrowDown';
import { MixedImage } from '@common/ui/images/mixed-image';
import { Footer } from '@common/ui/footer/footer';
import { Trans } from '@common/i18n/trans';
import { AdHost } from '@common/admin/ads/ad-host';
import { Link } from 'react-router-dom';
import { createSvgIconFromTree } from '@common/icons/create-svg-icon';
import { MenuItemConfig } from '@common/core/settings/settings';
import { Fragment } from 'react';
import { DefaultMetaTags } from '@common/seo/default-meta-tags';
import { useTrans } from '@common/i18n/use-trans';
import { useSettings } from '@common/core/settings/use-settings';
//import "../css/main.css"
import "../css/landingPage.css"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ChatBubbleIcon } from '@common/icons/material/ChatBubble';
import { FaArrowRight, FaBell, FaCloud, FaComment, FaCubes, FaFile, FaHeadset, FaInstagram, FaLinkedin, FaLock, FaPaperPlane, FaTwitter, FaUser, FaYoutube } from "react-icons/fa";
import { FaMessage } from "react-icons/fa6";
import NewFooter from '@common/ui/new-footer/NewFooter';

interface ContentProps {
  content: LandingPageContent;
}
export function LandingPage() {
  const settings = useSettings();
  const homepage = settings.homepage as { appearance: LandingPageContent };

  return (
    <Fragment>
      <DefaultMetaTags />
      <div>
        <HeroHeader content={homepage.appearance} />
        <PromoSection />
        <CallToAction />
        <ServicesSection />
        <PricingSection />
        <FeaturesSection />
        <CallToActionSecureFeatures />
        <TestimonialSection />
        <BlogSection />
        <NewFooter />
        <FooterBottom />
        {/* <AdHost slot="landing-top" className="mb-14 px-14 md:mb-60" /> */}
        {/* <PrimaryFeatures content={homepage.appearance} /> */}
        {/* <div className="my-40 h-1 bg-divider" /> */}
        {/* <SecondaryFeatures content={homepage.appearance} /> */}
        {/* <BottomCta content={homepage.appearance} /> */}
        {/* <Footer className="landing-container" /> */}
      </div>
    </Fragment>
  );
}

function HeroHeader({
  content: {
    headerTitle,
    headerSubtitle,
    headerImage,
    headerImageOpacity,
    actions,
    headerOverlayColor1,
    headerOverlayColor2,
  },
}: ContentProps) {
  const { trans } = useTrans();

  let overlayBackground = undefined;

  if (headerOverlayColor1 && headerOverlayColor2) {
    overlayBackground = `linear-gradient(45deg, ${headerOverlayColor1} 0%, ${headerOverlayColor2} 100%)`;
  } else if (headerOverlayColor1) {
    overlayBackground = headerOverlayColor1;
  } else if (headerOverlayColor2) {
    overlayBackground = headerOverlayColor2;
  }

  return (
    // <header
    //   className="relative mb-14 h-500 md:mb-60 md:h-screen"
    //   style={{background: overlayBackground}}
    // >
    //   <div
    //     data-testid="headerImage"
    //     className="absolute inset-0 z-10 bg-cover bg-1/2 bg-no-repeat md:bg-fixed"
    //     style={{
    //       backgroundImage: `url(${headerImage})`,
    //       opacity: headerImageOpacity,
    //     }}
    //   />
    //   <div className="relative z-20 flex h-full flex-col">
    //     <Navbar
    //       color="transparent"
    //       className="flex-shrink-0"
    //       menuPosition="homepage-navbar"
    //     />
    //     <div className="mx-auto flex max-w-850 flex-auto flex-col items-center justify-center px-14 text-center text-white">
    //       {headerTitle && (
    //         <h1
    //           className="text-4xl font-medium md:text-5xl"
    //           data-testid="headerTitle"
    //         >
    //           <Trans message={ } />
    //         </h1>
    //       )}
    //       {headerSubtitle && (
    //         <div
    //           className="mt-30 text-lg md:mt-10 md:text-xl"
    //           data-testid="headerSubtitle"
    //         >
    //           <Trans message={headerSubtitle} />
    //         </div>
    //       )}
    //       <div className="mt-30 flex min-h-50 gap-20">
    //         <CtaButton
    //           item={actions?.cta1}
    //           variant="raised"
    //           color="primary"
    //           size="lg"
    //           radius="rounded-full"
    //           data-testid="cta1"
    //           className="min-w-180"
    //         />
    //         <CtaButton
    //           item={actions?.cta2}
    //           variant="text"
    //           color="paper"
    //           size="lg"
    //           radius="rounded-full"
    //           data-testid="cta2"
    //         />
    //       </div>
    //     </div>
    //   </div>
    //   <IconButton
    //     size="lg"
    //     className="absolute bottom-5 left-0 right-0 z-30 mx-auto text-white max-md:hidden"
    //     elementType="a"
    //     aria-label={trans({message: 'View features'})}
    //     href="#primary-features"
    //   >
    //     <KeyboardArrowDownIcon />
    //   </IconButton>
    // </header>
    <>
      <div className='container mx-auto '>
        <Navbar
          color="transparent"
          className="flex-shrink-0"
          menuPosition="homepage-navbar"
        />
      </div>
      <section className="py-24 overflow-hidden primary-bg">
        <div className="container mx-auto">
          <div className="flex flex-col md:flex-row items-center justify-between md:justify-center sm:justify-center">
            <div className="md:w-5/12">
              <div className="hero-slider-content text-white py-5">
                <div className="headline mb-4">
                  <p className="mb-0 flex items-center">

                    <i className="rounded-full mr-2 flex justify-center items-center">
                    <FaBell    /></i> <span className="font-bold">17% Discount</span> first annual purchase</p>
                </div>
                <h1 className="text-white mb-2">Access your files <span className="block">anywhere, any time</span></h1>
                {headerSubtitle && (
                  <p className="text-white text-lg leading-relaxed"><Trans message={headerSubtitle} /></p>
                )}

                <div className="action-btns mt-4">
                  <Link to="register" className="btn btn-tertiary btn-lg">
                    Sign up free
                  </Link>
                </div>

              </div>
            </div>
            <div className="md:w-6/12 mt-6 md:mt-0">
              <div className="img-wrap">
                <img src="assets/img/file_upload.gif" alt="image" className="img-fluid" />
              </div>
            </div>
          </div>
          {/* end of flex */}
        </div>
        {/* end of container */}
      </section>
    </>
  );
}

interface CtaButtonProps extends ButtonProps {
  item?: MenuItemConfig;
}
function CtaButton({ item, ...buttonProps }: CtaButtonProps) {
  if (!item?.label) return null;
  const Icon = item.icon ? createSvgIconFromTree(item.icon) : undefined;
  return (
    <Button
      elementType={item.type === 'route' ? Link : 'a'}
      href={item.action}
      to={item.action}
      startIcon={Icon ? <Icon /> : undefined}
      {...buttonProps}
    >
      <Trans message={item.label} />
    </Button>
  );
}

function PromoSection() {
  return (
    <section className="promo-section py-[100px] ">
      <div className="container mx-auto">
        <div className="flex flex-col md:flex-row items-center justify-center mb-20">
          <div className="md:w-10/12 lg:w-6/12">
            <div className="section-heading text-center">
              <h2 className='dark:text-white'>We Provide You World Class Best File Host Cloud Features For You</h2>
              <p className='dark:text-white'>
                MyDocUploads connects your internal teams, external partners, clients, customers, documents, files, computers and mobile devices together seamlessly, so everyone is always up-to-date and on the same page.
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap justify-between gap-3">
          <div className="md:w-6/12 lg:w-[32%]">
            <div className="card single-promo-card single-promo-hover text-center p-2 mt-4 dark:bg-black/50">
              <div className="card-body">
                <center className="py-5">
                  <FaCubes className="fas fa-cubes icon-size-lg dark:text-white" />
                </center>
                <div className="py-10">
                  <h5 className='dark:text-white'>Fully Functional</h5>
                  <p className="mb-0 mt-12 dark:text-white">
                    MyDocUploads backs up your company's documents in real time, ensuring you can restore any file, any time. Recover from ransomware, hardware failure and human error in an instant. Whew!.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="md:w-6/12 lg:w-[32%]">
            <div className="card single-promo-card single-promo-hover text-center p-2 mt-4 dark:bg-black/50">
              <div className="card-body">
                <center className="py-5">
                  <FaHeadset className="fas fa-headset icon-size-lg dark:text-white" />
                </center>
                <div className="py-10">
                  <h5 className='dark:text-white'>Live Chat</h5>
                  <p className="mb-0 mt-12 dark:text-white">
                    Our Live Chat support to be there for you, ensuring that you can confidently navigate our platform and make the most out of its capabilities. Experience the convenience and peace of mind that comes with knowing help is just a click away.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="md:w-6/12 lg:w-[32%]">
            <div className="card single-promo-card single-promo-hover text-center p-2 mt-4 dark:bg-black/50">
              <div className="card-body">
                <center className="pb-2">
                  <FaLock className="fas fa-lock icon-size-lg dark:text-white" />
                </center>
                <div className="py-10">
                  <h5 className='dark:text-white'>Secure Data</h5>
                  <p className="mb-0 mt-12 dark:text-white">
                    MyDocUploads includes groundbreaking privacy protection features, enterprise grade infrastructure and certification with the most widely accepted security and privacy regulations worldwide. Your data is safe with MyDocUploads.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
function CallToAction() {
  return (
    <section className="py-[100px] primary-bg">
      <div className="container mx-auto">
        <div className="flex flex-col md:flex-row items-center justify-between">
          <div className="md:w-7/12 lg:w-7/12">
            <div className="cta-content-wrap text-white mb-20">
              <h2 className="text-white mb-10">Easy to use, reliable, private, and secure. <br /> With Trusted Service</h2>
              <p>MyDocUploads backs up your company's documents in real time, ensuring you can restore any file, any time. Recover from ransomware, hardware failure and human error in an instant. Whew!</p>
            </div>
            <div className="action-btns mt-4">
              <Link to="register" className="btn btn-tertiary">
                Sign up free
              </Link>
            </div>
          </div>
          <div className="md:w-5/12 lg:w-4/12">
            <div className="cta-img-wrap text-center">
              <img src="assets/img/cta-new.svg" className="img-fluid" alt="server room" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function ServicesSection() {
  return (
    <section className="pt-[100px] service-section-wrap">
      <div className="container mx-auto">
        <center>
          <div className="w-full md:w-10/12 lg:w-6/12">
            <div className="section-heading text-center mb-5">
              <h2 className='dark:text-white'>To infinity and beyond!</h2>
              <p className='dark:text-white'>
                Say goodbye to surprise transfer fees and stop worrying about running out of storage space again. Unlock the unlimited potential of your team with unlimited cloud storage and unlimited data transfer with our pro plans.
              </p>
            </div>
          </div>
        </center>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-30">
          <div className="col-span-1 md:col-span-2 lg:col-span-1 mt-20 ">
          <div className="single-service p-50 rounded border gray-light-bg dark:!bg-black/50">
          <div className="service-header flex items-center justify-between">
                <h4 className='dark:text-white'>
                  <span className="h5 text-uppercase dark:text-white">Easy & First</span>
                  <br />
                  Access your files anywhere, any time
                </h4>
                <FaFile className="fas fa-file fa-3x color-primary dark:text-white" />
              </div>
              <p className='dark:text-white'>
                Access your files instantly from all your computers, mobile devices and the web. Work from home, from the office or from the most idea-inspiring places on the planet.
              </p>
            </div>
          </div>
          <div className="col-span-1 md:col-span-2 lg:col-span-1 mt-20">
            <div className="single-service p-50 rounded border gray-light-bg dark:!bg-black/50">
              <div className="service-header flex items-center justify-between">
                <h4 className='dark:text-white'>
                  <span className="h5 text-uppercase dark:text-white">Suitable For All Users</span>
                  <br />
                  Keep all your work always backed up
                </h4>
                <FaCloud className="fa fa-cloud fa-3x color-primary dark:text-white" />
              </div>
              <p className='dark:text-white'>
                MyDocUploads backs up your company's documents in real time, ensuring you can restore any file, any time. Recover from ransomware, hardware failure and human error in an instant. Whew!
              </p>
            </div>
          </div>
          <div className="col-span-1 md:col-span-2 lg:col-span-1 mt-20">
            <div className="single-service p-50 rounded border gray-light-bg dark:!bg-black/50">
              <div className="service-header flex items-center justify-between">
                <h4 className='dark:text-white'>
                  <span className="h5 text-uppercase dark:text-white">Commitment To</span>
                  <br />
                  Secure Message
                </h4>
                <FaMessage className="fas fa-message fa-3x color-primary dark:text-white"/>
              </div>
              <p className='dark:text-white'>
                Send encrypted messages and file attachments to anyone!. Once your link expires, we will delete\purge any information related to your shared link from our databases and will never keep any archived data.
              </p>
            </div>
          </div>
          <div className="col-span-1 md:col-span-2 lg:col-span-1 mt-20">
            <div className="single-service p-50 rounded border gray-light-bg dark:!bg-black/50">
              <div className="service-header flex items-center justify-between">
                <h4 className='dark:text-white'>
                  <span className="h5 text-uppercase dark:text-white">Easy & Smooth</span>
                  <br />
                  Send and receive files professionally
                </h4>
                <FaPaperPlane className="fas fa-paper-plane fa-3x color-primary dark:text-white" />
              </div>
              <p className='dark:text-white'>
                Share your documents through a custom-branded client file portal with your company logo on full display. Build client trust and set your business apart.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function PricingSection() {
  return (
    <section className="pricing-section py-[100px] gray-light-bg dark:!bg-transparent">
      <div className="container mx-auto">
        <center>
          <div className="w-full md:w-9/12 lg:w-6/12">
            <div className="section-heading text-center mb-4">
              <h2 className='dark:text-white'>Choose the right plan for you</h2>
              <p className='dark:text-white'>
                You can use the billing page in your account settings to update your payment method with a different credit card or PayPal account, change billing frequency from monthly or yearly, and view invoices and receipts from your billing history.
              </p>
            </div>
          </div>
        </center>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-20 mt-50">
          <div className="col-span-1 mt-20">
            <div className="text-center bg-white single-pricing-pack-2 mt-4 rounded border dark:border-white">
              <div className="pricing-icon">
                <img src="assets/img/dadicate-web-hosting.svg" width="60" alt="hosing" />
              </div>
              <h4 className="package-title h5 mb-16 dark:!text-white">Basic</h4>
              <p className="mb-16">For basic use</p>
              <div className="pricing-price pt-16">
                <p className="mb-1 dark:text-white">10GB of Secure Storage Space</p>
                <p className="mb-1 dark:text-white">Secure & Fast Cloud Servers</p>
                <p className="mb-1 dark:text-white">Two Factor Authentication (2FA)</p>
                <p className="mb-1 dark:text-white">256-bit AES and SSL/TLS encryption</p>
                <p className="mb-1 dark:text-white">Advanced Link Share Controls</p>
                <p className="mb-1 dark:text-white">Preview Stored Documents</p>
                <p className="mb-1 dark:text-white">Secure Message - Limited</p>
                <small>Starting at</small>
                <div className="h2 dark:!text-white">Free <span className="price-cycle h4"></span></div>
              </div>
              <a href="/pricing" className="btn btn-primary mt-16">Get Started Now</a>
            </div>
          </div>
          <div className="col-span-1">
            <div className="popular-price text-center bg-white single-pricing-pack-2 mt-4 rounded border dark:border-white">
              <div className="pricing-icon">
                <img src="assets/img/vps-hosting.svg" width="60" alt="hosing" />
              </div>
              <h4 className="package-title h5 mb-16 dark:!text-white">Plus</h4>
              <p className="mb-16">For medium business</p>
              <div className="pricing-price pt-16">
                <p className="mb-1 dark:text-white">2TB of Secure Storage Space</p>
                <p className="mb-1 dark:text-white">Secure & Fast Cloud Servers</p>
                <p className="mb-1 dark:text-white">Two Factor Authentication (2FA)</p>
                <p className="mb-1 dark:text-white">256-bit AES and SSL/TLS encryption</p>
                <p className="mb-1 dark:text-white">Advanced Link Share Controls</p>
                <p className="mb-1 dark:text-white">Preview Stored Documents</p>
                <p className="mb-1 dark:text-white">Secure Message - Limited</p>
                <p className="mb-1 dark:text-white">Add Secure Workspaces</p>
                <p className="mb-1 dark:text-white">Priority Email Support</p>
                <small>Starting at</small>
                <div className="h2 dark:!text-white">$9 <span className="price-cycle h4">/mo</span></div>
              </div>
              <a href="/pricing" className="btn btn-primary mt-16">Get Started Now</a>
            </div>
          </div>
          <div className="col-span-1">
            <div className="text-center bg-white single-pricing-pack-2 mt-4 rounded border dark:border-white">
              <div className="pricing-icon">
                <img src="assets/img/cloud-hosting.svg" width="60" alt="hosing" />
              </div>
              <h4 className="package-title h5 mb-16 dark:!text-white">Professional</h4>
              <p className="mb-16">Large and enterprise business</p>
              <div className="pricing-price pt-16">
                <p className="mb-1 dark:text-white">10TB of Secure Storage Space</p>
                <p className="mb-1 dark:text-white">Secure & Fast Cloud Servers</p>
                <p className="mb-1 dark:text-white">Two Factor Authentication (2FA)</p>
                <p className="mb-1 dark:text-white">256-bit AES and SSL/TLS encryption</p>
                <p className="mb-1 dark:text-white">Advanced Link Share Controls</p>
                <p className="mb-1 dark:text-white">Preview Stored Documents</p>
                <p className="mb-1 dark:text-white">Secure Message - Limited</p>
                <p className="mb-1 dark:text-white">Add Secure Workspaces</p>
                <p className="mb-1 dark:text-white">Priority Email Support</p>
                <small>Starting at</small>
                <div className="h2 dark:!text-white">$19 <span className="price-cycle h4">/mo</span></div>
              </div>
              <a href="/pricing" className="btn btn-primary mt-16">Get Started Now</a>
            </div>
          </div>
          {/* <div className="col-span-1">
            <div className="support-cta text-center mt-5">
              <h5 className="mb-1">
                <span className="fas fa-headset color-primary me-3"></span>We're Here
                to Help You
              </h5>
              <p>
                Have some questions? <a href="contact">Chat with us now</a>, or
                <a href="contact">send us an email</a> to get in touch.
              </p>
            </div>
          </div> */}
        </div>
        <div className="grid grid-cols-1">
          <div className='flex justify-center items-center'>
            <div className="support-cta mt-50 flex justify-center items-center flex-col">
              <h5 className="mb-1 flex gap-2 dark:text-white">
              <FaHeadset  className='me-3 mt-5'/>


                We're Here
                to Help You
              </h5>
              <p className='dark:text-white'>
                Have some questions? <a href="contact">Chat with us now</a>, or
                <a href="contact">send us an email</a> to get in touch.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function FeaturesSection() {
  return (
    <div className="feature-section py-[100px]">
      <div className="container mx-auto">
        <div className="flex flex-col md:flex-row items-center justify-between">
          <div className="md:w-7/12 lg:w-5/12">
            <div className="feature-content-wrap">
              <h2 className='dark:text-white'>Store any file or folder</h2>
              <p className='mt-16 dark:text-white'>No matter the file or folder you’re trying to save—from photos and videos to large CAD files and PowerPoint presentations—you can store it safely using cloud storage solutions from MyDocUploads. </p>
              <p className='mt-16 dark:text-white'>MyDocUploads offers one central hub for online file storage, file sharing, and syncing. Whether you’re at work or on the road, your files are synced across your devices and accessible in real time. </p>
              <a href="/pages/about-us" className="read-more-link mt-16 flex items-center">Know more about us <FaArrowRight className="fas fa-arrow-right ms-10" /></a>
            </div>
          </div>
          <div className="md:w-5/12 lg:w-6/12 hidden md:block lg:block">
            <div className="feature-img-wrap text-center">
              <img src="assets/img/cloud-file.png" className="img-fluid" alt="server room" />
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row-reverse items-center justify-between mt-5">
          <div className="md:w-7/12 lg:w-5/12">
            <div className="feature-content-wrap">
              <h2 className='dark:text-white'>Protect your data</h2>
              <p className='mt-16 dark:text-white'>If your device is lost or stolen, rest easy knowing your data’s safe. Remote wipe lets you clear all the files and folders from your missing device’s MyDocUploads account. All of your files are securely saved in cloud storage and easily retrievable.</p>
              <a href="/pages/about-us" className="read-more-link flex items-center">Know more about us <FaArrowRight className="fas fa-arrow-right ms-10" /></a>
            </div>
          </div>
          <div className="md:w-5/12 lg:w-6/12 hidden md:block lg:block">
            <div className="feature-img-wrap text-center">
              <img src="assets/img/feature-new-2.svg" className="img-fluid" alt="server room" />
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

function CallToActionSecureFeatures() {
  return (
    <section className="py-[60px] primary-bg">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row items-center justify-between">
          <div className="w-full lg:w-6/12">
            <div className="cta-content-wrap text-white">
              <h2 className="text-white">
                Secure Message Feature
              </h2>
              <p className='mt-16'>
                Send encrypted messages and file attachments to anyone! Once your link expires, we will delete/purge any information related to your shared link from our databases and will never keep any archived data.
              </p>
            </div>
            <div className="support-action flex flex-wrap"></div>
          </div>
          <div className="hidden md:block lg:w-4/12">
            <div className="cta-img-wrap text-center">
              <img src="assets/img/Secure_Message.png" width="250" className="img-fluid" alt="server room" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export function TestimonialSection() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  return (
    <section className="review-section pt-[100px] ">
      <div className="container mx-auto">
        <div className="flex justify-center items-center">
          <div className="w-1/2">
            <div className="section-heading text-center">
              <h2 className='dark:text-white'>What Clients Say About Us</h2>
              <p className='dark:text-white'>We are very fortunate to have formed excellent partnerships with many of our clients. And we’ve formed more than just working relationships with them; we have formed true friendships. Here’s what they’re saying about us.</p>
            </div>
          </div>
        </div>
        <div className="mt-50">

          <Carousel
            responsive={responsive}
            showDots={true}
            customTransition="all .5"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={2000}>
            <div className="item">
              <div className="border single-review-wrap bg-white p-4 m-3">
                <div className="review-body">
                  <h5>Super fast upload</h5>
                  <p>I've been using this file upload service for my business for over a year now, and I can't imagine life without it! It's made sharing large documents with my team effortless and secure.</p>
                </div>
                <div className="review-author d-flex align-items-center">
                  <div className="author-avatar">
                    <img src="assets/img/clients/client-1.png" width="64" alt="author" className="rounded-circle shadow-sm img-fluid mr-3" />
                    <span>“</span>
                  </div>
                  <div className="review-info">
                    <h6 className="mb-0">Cravens</h6>
                    <span>BizBite</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="border single-review-wrap bg-white p-4 m-3">
                <div className="review-body">
                  <h5 className="text-lg font-semibold">Most secure and safe</h5>
                  <p className="text-gray-700">I needed a way to securely share confidential documents with my clients, and this file upload website exceeded my expectations. The password protection feature gives me peace of mind knowing that only authorized individuals can access my files</p>
                </div>
                <div className="review-author flex items-center">
                  <div className="author-avatar">
                    <img src="assets/img/clients/client-2.png" width="64" alt="author" className="rounded-full shadow-sm mr-3" />
                    <span>“</span>
                  </div>
                  <div className="review-info">
                    <h6 className="mb-0">Kevin</h6>
                    <span>Team9</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="item">
              <div className="border single-review-wrap bg-white p-4 m-3">
                <div className="review-body">
                  <h5 className="text-lg font-semibold">Efficiently innovate</h5>
                  <p className="text-gray-700">I've tried several file upload services in the past, but none compare to this one. The interface is intuitive, the upload speeds are lightning-fast, and the customer support team is top-notch.</p>
                </div>
                <div className="review-author flex items-center">
                  <div className="author-avatar">
                    <img src="assets/img/clients/client-3.png" width="64" alt="author" className="rounded-full shadow-sm mr-3" />
                    <span>“</span>
                  </div>
                  <div className="review-info">
                    <h6 className="mb-0">Martin</h6>
                    <span>CloudCore</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="item">
              <div className="border single-review-wrap bg-white p-4 m-3">
                <div className="review-body">
                  <h5 className="text-lg font-semibold">Uniquely flexible</h5>
                  <p className="text-gray-700">This file upload platform has completely streamlined my workflow. Whether I'm collaborating with colleagues or sending files to friends and family, it's always my go-to choice.</p>
                </div>
                <div className="review-author flex items-center">
                  <div className="author-avatar">
                    <img src="assets/img/clients/client-4.png" width="64" alt="author" className="rounded-full shadow-sm mr-3" />
                    <span>“</span>
                  </div>
                  <div className="review-info">
                    <h6 className="mb-0">Mark Fleming</h6>
                    <span>Twitter</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="border single-review-wrap bg-white p-4 m-3">
                <div className="review-body">
                  <h5 className="text-lg font-semibold">Compellingly empower app</h5>
                  <p className="text-gray-700">I run a small photography business, and this website has been a game-changer for sharing proofs and final edits with my clients. It's incredibly convenient and has helped me impress clients with my professionalism.</p>
                </div>
                <div className="review-author flex items-center">
                  <div className="author-avatar">
                    <img src="assets/img/clients/client-5.png" width="64" alt="author" className="rounded-full shadow-sm mr-3" />
                    <span>“</span>
                  </div>
                  <div className="review-info">
                    <h6 className="mb-0">Matt</h6>
                    <span>MaxC</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="border single-review-wrap bg-white p-4 m-3">
                <div className="review-body">
                  <h5 className="text-lg font-semibold">Holisticly reintermediate</h5>
                  <p className="text-gray-700">I can't believe I used to struggle with unreliable file transfer methods before discovering this website. Now, sending large files to my coworkers is a breeze – no more email attachment limits or USB drives to worry about.</p>
                </div>
                <div className="review-author flex items-center">
                  <div className="author-avatar">
                    <img src="assets/img/clients/client-6.png" width="64" alt="author" className="rounded-full shadow-sm mr-3" />
                    <span>“</span>
                  </div>
                  <div className="review-info">
                    <h6 className="mb-0">Svenson</h6>
                    <span>BuzzMi</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="border single-review-wrap bg-white p-4 m-3">
                <div className="review-body">
                  <h5 className="text-lg font-semibold">Uniquely mesh flexible</h5>
                  <p className="text-gray-700">I'm not the most tech-savvy person, but this file upload website couldn't be easier to use. I love how straightforward it is to upload and organize my files – it's like having my own personal file manager in the cloud.</p>
                </div>
                <div className="review-author flex items-center">
                  <div className="author-avatar">
                    <img src="assets/img/clients/client-7.png" width="64" alt="author" className="rounded-full shadow-sm mr-3" />
                    <span>“</span>
                  </div>
                  <div className="review-info">
                    <h6 className="mb-0">Grant</h6>
                    <span>Hostrs</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="border single-review-wrap bg-white p-4 m-3">
                <div className="review-body">
                  <h5 className="text-lg font-semibold">Compellingly empower app</h5>
                  <p className="text-gray-700">I've been recommending this file upload service to all of my friends and colleagues. It's the perfect solution for anyone who needs a secure, efficient way to share files online.</p>
                </div>
                <div className="review-author flex items-center">
                  <div className="author-avatar">
                    <img src="assets/img/clients/client-8.png" width="64" alt="author" className="rounded-full shadow-sm mr-3" />
                    <span>“</span>
                  </div>
                  <div className="review-info">
                    <h6 className="mb-0">Damon</h6>
                    <span>BestBuy</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="border single-review-wrap bg-white p-4 m-3">
                <div className="review-body">
                  <h5 className="text-lg font-semibold">Holisticly reintermediate</h5>
                  <p className="text-gray-700">I've tried other file upload platforms in the past, but they always seemed to have limitations that frustrated me. This website, however, offers everything I need and more – it's truly a game-changer for my productivity.</p>
                </div>
                <div className="review-author flex items-center">
                  <div className="author-avatar">
                    <img src="assets/img/clients/client-9.png" width="64" alt="author" className="rounded-full shadow-sm mr-3" />
                    <span>“</span>
                  </div>
                  <div className="review-info">
                    <h6 className="mb-0">Kowarsky</h6>
                    <span>Targget</span>
                  </div>
                </div>
              </div>
            </div>

          </Carousel>
        </div>
      </div>
    </section>
  );
}

function BlogSection() {
  return (
    <section className="our-blog-section py-[100px] bg-gray-100">
      <div className="container mx-auto">
        <div className="text-center">
          <h2 className="text-3xl font-semibold mb-4">Recent News and Events</h2>
          <p className="text-gray-600">Expand your knowledge through in-depth articles, webinars, tips and tricks, and the latest news.</p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-20 mb-5">
          <div>
            <a href="blog" className="single-blog-article border rounded bg-white block p-30 mt-40">
              <div className="article-heading">
                <h3 className="text-lg font-semibold mb-1">Turning beloved hobbies into a thriving business</h3>
                <span className="text-sm dark:!text-white">On 20 Mar, 2024</span>
              </div>
              <hr className="border-t my-2" />
              <p className="text-gray-700 dark:text-white">Music had always been her true passion, but in Tallahassee, art was just a hobby, not a career. So Bell worked as an...</p>
              <div className="article-footer flex items-center justify-between mt-3">
                <div className="article-comments">
                  <span className='flex items-center'><FaComment className='me-3' /> 34 Comments</span>
                </div>
                <div className="article-user flex items-center justify-center">
                  <FaUser className="fas fa-user" />
                </div>
              </div>
            </a>
          </div>
          <div>
            <a href="blog" className="single-blog-article border rounded bg-white block p-30 mt-40">
              <div className="article-heading">
                <h3 className="text-lg font-semibold mb-1 ">How AI could change therapy for the better</h3>
                <span className="text-sm dark:!text-white">On 20 Fab, 2024</span>
              </div>
              <hr className="border-t my-2" />
              <p className="text-gray-700 dark:text-white">Artificial intelligence is making huge waves in psychotherapy—and not just through the use of chatbots. One psychologist and...</p>
              <div className="article-footer flex items-center justify-between mt-3">
                <div className="article-comments">
                  <span className='flex items-center'><FaComment className='me-3' /> 24 Comments</span>
                </div>
                <div className="article-user flex items-center justify-center">
                  <FaUser className="fas fa-user" />
                </div>
              </div>
            </a>
          </div>
          <div>
            <a href="blog" className="single-blog-article border rounded bg-white block p-30 mt-40">
              <div className="article-heading">
                <h3 className="text-lg font-semibold mb-1 ">The key to getting a Sundance film made is…</h3>
                <span className="text-sm dark:!text-white">On 10 Mar, 2024</span>
              </div>
              <hr className="border-t my-2" />
              <p className="text-gray-700 dark:text-white">At this year’s Sundance Film Festival, 62% of the films were made using MyDocUploads. (A pretty impressive number, if we do say so...</p>
              <div className="article-footer flex items-center justify-between mt-3">
                <div className="article-comments">
                  <span className='flex items-center'><FaComment className='me-3' /> 20 Comments</span>
                </div>
                <div className="article-user flex items-center justify-center">
                  <FaUser className="fas fa-user" />
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

// function NewFooter() {
//   return (
//     <footer className="footer-1 py-[60px] gradient-bg">
//       <div className="container mx-auto py-4">
//         <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
//           <div className="col-span-1 md:col-span-1 lg:col-span-1 mb-8 md:mb-0">
//             <a href="#" className="block mb-4">
//               <img src="storage/branding_media/c4eba1c8-37c5-4a75-ae75-da419d048f57.png" alt="logo" width="150" height="40" className="img-fluid" />
//             </a>
//             <p className="text-gray-700 mt-20">
//               MyDocUploads is a file storage and document collaboration platform that helps teams stay safe, secure and connected in the cloud.
//             </p>
//             <ul className="list-inline social-list-default background-color social-hover-2 mt-20   flex">
//               <li className="list-inline-item">
//                 <a className="twitter" target="_blank" href="#">
//                   <FaTwitter className="fab fa-twitter"/>
//                   </a>
//               </li>
//               <li className="list-inline-item">
//                 <a className="youtube" target="_blank" href="#">
//                   <FaYoutube className="fab fa-youtube" />
//                   </a>
//               </li>
//               <li className="list-inline-item">
//                 <a className="linkedin" target="_blank" href="#">
//                   <FaLinkedin className="fab fa-linkedin-in" />
//                   </a>
//               </li>
//               <li className="list-inline-item">
//                 <a className="instagram" target="_blank" href="#">
//                   <FaInstagram className="fab fa-instagram"/>
//                   </a>
//               </li>
//             </ul>
//           </div>
//           <div className="col-span-1 md:col-span-1 lg:col-span-1">
//             <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
//               <div>
//                 <h6 className="font-weight-normal">Resources</h6>
//                 <ul>
//                   <li><a href="/pages/help">Help</a></li>
//                   <li><a href="/pages/terms-of-service">Terms of Service</a></li>
//                   <li><a href="/pages/security-privacy">Security & Privacy</a></li>
//                   <li><a href="/pages/about-us">About Us</a></li>
//                   <li><a href="/api-docs">API Documentation</a></li>
//                 </ul>
//               </div>
//               <div>
//                 <h6 className="font-weight-normal">Products</h6>
//                 <ul>
//                   <li><a href="pricing">Pricing</a></li>
//                   <li><a href="blog">Blog</a></li>
//                   <li><a href="#">AI Studio</a></li>
//                   <li><a href="#">Enterprise</a></li>
//                   <li><a href="#">Performance</a></li>
//                 </ul>
//               </div>

//             </div>
//           </div>
//           <div className="col-span-1 md:col-span-1 lg:col-span-1">
//             <div className="grid grid-cols-1 md:grid-cols-2 gap-8">

//               <div>
//                 <h6 className="font-weight-normal">Company</h6>
//                 <ul>
//                   <li><a href="/pages/about-us">Mission</a></li>
//                   <li><a href="#">Careers</a></li>
//                   <li><a href="contact">Contact Us</a></li>
//                   <li><a href="#">Community</a></li>
//                   <li><a href="/pages/about-us">Our Team</a></li>
//                 </ul>
//               </div>
//               <div>
//                 <h6 className="font-weight-normal">Support</h6>
//                 <ul>
//                   <li><Link to="/faq">FAQ</Link></li>
//                   <li><a href="login">My Account</a></li>
//                   <li><a href="register">Register</a></li>
//                   <li><a href="/pages/solutions">Solutions</a></li>
//                   <li><a href="/pages/features">Features</a></li>
//                 </ul>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </footer>
//   );
// }
function FooterBottom() {
  return (
    <div className="footer-bottom py-20 bg-gray-100">
      <div className="container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="col-span-1 md:col-span-1 lg:col-span-7">
            <div className="copyright-wrap text-center md:text-left">
              <p className="text-sm mb-0">
                Copyright &copy; 2024 MyDocUploads, All Rights Reserved
              </p>
            </div>
          </div>
          <div className="col-span-1 md:col-span-1 lg:col-span-5">
            <div className="terms-policy-wrap text-center md:text-right">
              <ul className="list-inline">
                {/* Add list items for terms and policy links here */}
                {/* Example: */}
                {/* <li className="list-inline-item">
                  <a href="#">Terms of Use</a>
                </li>
                <li className="list-inline-item">
                  <a href="#">Privacy Policy</a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function PrimaryFeatures({ content }: ContentProps) {
  return (
    <div
      className="landing-container items-stretch gap-26 md:flex"
      id="primary-features"
    >
      {content?.primaryFeatures?.map((feature, index) => (
        <div
          key={index}
          className="mb-14 flex-1 rounded-2xl px-24 py-36 text-center shadow-[0_10px_30px_rgba(0,0,0,0.08)] md:mb-0"
          data-testid={`primary-root-${index}`}
        >
          <MixedImage
            className="mx-auto mb-30 h-128"
            data-testid={`primary-image-${index}`}
            src={feature.image}
          />
          <h2
            className="my-16 text-lg font-medium"
            data-testid={`primary-title-${index}`}
          >
            <Trans message={feature.title} />
          </h2>
          <div
            className="text-md text-[0.938rem]"
            data-testid={`primary-subtitle-${index}`}
          >
            <Trans message={feature.subtitle} />
          </div>
        </div>
      ))}
    </div>
  );
}

function SecondaryFeatures({ content }: ContentProps) {
  return (
    <div className="landing-container">
      {content?.secondaryFeatures?.map((feature, index) => {
        const isEven = index % 2 === 0;
        return (
          <div
            key={index}
            data-testid={`secondary-root-${index}`}
            className={clsx(
              'mb-14 py-16 md:mb-80 md:flex',
              isEven && 'flex-row-reverse',
            )}
          >
            <img
              src={feature.image}
              className="mr-auto w-580 max-w-full rounded-lg shadow-[0_10px_30px_rgba(0,0,0,0.08)]"
              data-testid={`secondary-image-${index}`}
              alt=""
            />
            <div className="ml-30 mr-auto max-w-350 pt-30">
              <small
                className="mb-16 text-xs font-medium uppercase tracking-widest text-muted"
                data-testid={`secondary-subtitle-${index}`}
              >
                <Trans message={feature.subtitle} />
              </small>
              <h3
                className="py-16 text-3xl"
                data-testid={`secondary-title-${index}`}
              >
                <Trans message={feature.title} />
              </h3>
              <div className="h-2 w-50 bg-black/90" />
              <div
                className="my-20 text-[0.938rem]"
                data-testid={`secondary-description-${index}`}
              >
                <Trans message={feature.description} />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

function BottomCta({ content }: ContentProps) {
  return (
    <div
      className="relative bg-[#2B2B2B] bg-no-repeat py-70 text-center text-on-primary md:bg-fixed"
      style={{ backgroundImage: `url("${content.footerImage}")` }}
      data-testid="footerImage"
    >
      <h2
        className="mx-auto max-w-620 text-3xl font-normal"
        data-testid="footerTitle"
      >
        <Trans message={content.footerTitle} />
      </h2>
      {content.footerSubtitle && (
        <p
          className="mx-auto mt-50 max-w-620 text-2xl font-normal"
          data-testid="footerSubtitle"
        >
          <Trans message={content.footerSubtitle} />
        </p>
      )}
      <CtaButton
        item={content.actions?.cta3}
        size="lg"
        variant="outline"
        color="paper"
        className="mt-50 block"
        data-testid="cta3"
      />
    </div> 
  );
}
