import { Navbar } from '@common/ui/navigation/navbar/navbar'
import React, { useState } from 'react'
import AddBar from '../AddBar'
import NewFooter from '@common/ui/new-footer/NewFooter'
import EverythingCardComp from './components/EverythingCardComp'
import ContactCard from '@common/ui/contact-card/ContactCard'
import { BillingCycleRadio } from '@common/billing/pricing-table/billing-cycle-radio'
import { useProducts } from '@common/billing/pricing-table/use-products'
import { UpsellBillingCycle } from '@common/billing/pricing-table/find-best-price'
import { PricingTable } from '@common/billing/pricing-table/pricing-table'

const SecureCloudStorage = () => {
    return (
        <>
            <div className="container mx-auto">
                <Navbar
                    color="transparent"
                    className="flex-shrink-0"
                    menuPosition="homepage-navbar"
                />
            </div>
            <AddBar />
            <SecureCloudPage />
            <NewFooter />
        </>
    )
}

export default SecureCloudStorage


function SecureCloudPage() {
    const query = useProducts('pricingPage');
    const [selectedCycle, setSelectedCycle] =
        useState<UpsellBillingCycle>('yearly');
    return (
        <>

            <HeroSection />
            <div className="container mx-auto">
                <div className="grid grid-col-1 justify-center">
                    <h2 className="text-gray-900 text-center text-5xl font-bold mt-90 max-w-6xl">
                        MyDocUploads Secure Cloud Storage:     <span className="text-primary">Secure, Reliable, & Effortless Document Management:</span>
                    </h2>
                </div>

                <div className="grid lg:grid-cols-2 gap-20 lg:mt-32 items-center">
                    <div className='mt-32'>
                        <h2 className="text-start dark:text-white text-5xl font-bold mt-20 lg:mt-60">
                            Why Choose   <span className="text-primary">MyDocUploads Secure Cloud Storage?</span></h2>
                        <p className='text-base font-normal text-[#303030B2] dark:text-white tracking-tighter mt-32'>Businesses and individuals need a secure, accessible, and reliable solution for storing important documents. MyDocUploads Secure Cloud Storage provides an efficient, encrypted, and user-friendly platform that ensures your files are protected and accessible whenever and wherever you need them.</p>
                    </div>
                    <div className='mt-32 flex justify-center'>
                        <img src="./public/images/solutions/secure-3.svg" alt="" />
                    </div>
                </div>
                <div className="grid grid-col-1 justify-center">
                    <h2 className="text-gray-900 text-start text-5xl font-bold mt-90">
                        Benefits of MyDocUploads Secure Cloud Storage
                    </h2>
                </div>

                <div className="grid lg:grid-cols-2 gap-20 lg:mt-32 items-center">
                    <div className='mt-32'>
                        <h2 className="text-start dark:text-white text-5xl font-bold mt-20 lg:mt-60">
                            Advanced    <span className="text-primary">Security & Data Protection</span></h2>
                        <p className='text-base font-normal text-[#303030B2] dark:text-white tracking-tighter mt-32'>Businesses and individuals need a secure, accessible, and reliable solution for storing important documents. MyDocUploads Secure Cloud Storage provides an efficient, encrypted, and user-friendly platform that ensures your files are protected and accessible whenever and wherever you need them.</p>

                        <div className='mt-32'>
                            <div className='flex xl:items-center gap-5 mb-5'>
                                <img src="./public/images/about-us/correct-icon.png" alt="" />
                                <p className='text-sm text-[#303030B2] dark:text-white font-normal'>End-to-End Encryption: Your files are encrypted both in transit and at rest, ensuring maximum security.</p>
                            </div>
                            <div className='flex xl:items-center gap-5 mb-5'>
                                <img src="./public/images/about-us/correct-icon.png" alt="" />
                                <p className='text-sm text-[#303030B2] dark:text-white font-normal'>Multi-Factor Authentication (MFA): Extra layers of security to prevent unauthorized access.</p>
                            </div>
                            <div className='flex xl:items-center gap-5 mb-5'>
                                <img src="./public/images/about-us/correct-icon.png" alt="" />
                                <p className='text-sm text-[#303030B2] dark:text-white font-normal'>Automated Backups: Never lose critical documents with our scheduled automatic backups.</p>
                            </div>

                            <div className='flex xl:items-center gap-5 mb-5'>
                                <img src="./public/images/about-us/correct-icon.png" alt="" />
                                <p className='text-sm text-[#303030B2] dark:text-white font-normal'>Compliance Ready: Meets industry security standards for HIPAA, GDPR, and SOC 2 compliance.</p>
                            </div>
                            <div className='flex xl:items-center gap-5 mb-5'>
                                <img src="./public/images/about-us/correct-icon.png" alt="" />
                                <p className='text-sm text-[#303030B2] dark:text-white font-normal'>File are automatically scanned for malware upon uploading or downloading to MyDocUploads.</p>
                            </div>
                            <div className='flex xl:items-center gap-5 mb-5'>
                                <img src="./public/images/about-us/correct-icon.png" alt="" />
                                <p className='text-sm text-[#303030B2] dark:text-white font-normal'>Enterprise-grade Infrastructure </p>
                            </div>
                        </div>
                    </div>
                    <div className='mt-32 flex justify-center'>
                        <img src="./public/images/solutions/secure-2.svg" alt="" />
                    </div>
                </div>

                <div className="grid lg:grid-cols-2 gap-20 lg:mt-32 items-center">
                    <div className='mt-32 flex justify-center'>
                        <img src="./public/images/solutions/secure-4.svg" alt="" />
                    </div>
                    <div className='mt-32'>
                        <h2 className="text-start dark:text-white text-5xl font-bold mt-20 lg:mt-60">
                            Easy     <span className="text-primary">Accessibility & Collaboration</span></h2>
                        <p className='text-base font-normal text-[#303030B2] dark:text-white tracking-tighter mt-32'>Anywhere, Anytime Access: Securely access your files from any device, desktop, tablet, or mobile.</p>

                        <div className='mt-32'>
                            <div className='flex xl:items-center gap-5 mb-5'>
                                <img src="./public/images/about-us/correct-icon.png" alt="" />
                                <p className='text-sm text-[#303030B2] dark:text-white font-normal'>Team Workspaces: Share files, collaborate on projects, and manage team permissions seamlessly.</p>
                            </div>
                            <div className='flex xl:items-center gap-5 mb-5'>
                                <img src="./public/images/about-us/correct-icon.png" alt="" />
                                <p className='text-sm text-[#303030B2] dark:text-white font-normal'>Link Sharing & Expiry: Share files securely with time-restricted access links.</p>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="grid lg:grid-cols-2 gap-20 lg:mt-32 items-center">

                    <div className='mt-32'>
                        <h2 className="text-start dark:text-white text-5xl font-bold mt-20 lg:mt-60">
                            <span className="text-primary">Scalability & Storage </span>Flexibility</h2>
                        <p className='text-base font-normal text-[#303030B2] dark:text-white tracking-tighter mt-32'>Custom Storage Plans: Choose from multiple plans based on your storage needs.</p>

                        <div className='mt-32'>
                            <div className='flex xl:items-center gap-5 mb-5'>
                                <img src="./public/images/about-us/correct-icon.png" alt="" />
                                <p className='text-sm text-[#303030B2] dark:text-white font-normal'>Scalable Solutions: Upgrade storage capacity as your business grows.</p>
                            </div>
                            <div className='flex xl:items-center gap-5 mb-5'>
                                <img src="./public/images/about-us/correct-icon.png" alt="" />
                                <p className='text-sm text-[#303030B2] dark:text-white font-normal'>AI-Powered Organization: Smart categorization and search functionality for easy document retrieval.</p>
                            </div>
                        </div>
                    </div>
                    <div className='mt-32 flex justify-center'>
                        <img src="./public/images/solutions/secure-5.svg" alt="" />
                    </div>

                </div>

                <div className="grid grid-cols-1 gap-20  items-center justify-center lg:mt-60 ">
                    <div className='bg-[#00348E] rounded-3xl py-52 text-center flex flex-col items-center justify-center'>
                        <h2 className=" text-white text-4xl font-bold mt-20 text-center">
                            Why Switch to MyDocUploads Secure Cloud Storage?</h2>
                        <p className='text-base font-normal  text-white tracking-tighter mt-32 text-center max-w-5xl'>If you’re still relying on traditional storage methods or using outdated cloud solutions, now is the time to switch to MyDocUploads for a safer, more efficient, and cost-effective experience.</p>
                    </div>
                </div>
                <div className="grid md:grid-cols-2 gap-20 md:gap-0 lg:mt-52 items-center justify-center">
                    <div className='card dark:bg-[#000] !rounded-3xl md:!rounded-tr-none md:!rounded-br-none mt-32'>
                        <div className="card-header flex items-center justify-center bg-[#3B82F6] rounded-t-3xl md:rounded-tr-none md:rounded-br-none">
                            <h2 className='text-white text-center text-2xl font-bold p-22 max-w-sm'>Why MyDocUploads
                                is the Best Alternative?</h2>
                        </div>
                        <div className="card-body !py-0">
                            <div className='py-32 px-22'>
                                <div className='flex gap-10 items-center py-10 px-16 rounded-full bg-[#F7F7F7] border border-[#E6E6E6]'>
                                    <img src="./public/images/solutions/tick-check.svg" className='h-[22px] w-[22px]' alt="" />
                                    <p className='text-base font-semibold text-[#3F3F3F]'>Competitive plans with no hidden fees.</p>
                                </div>
                            </div>
                            <hr className='-mx-16' />
                            <div className='py-32 px-22'>
                                <div className='flex gap-10 items-center py-10 px-16 rounded-full bg-[#F7F7F7] border border-[#E6E6E6]'>
                                    <img src="./public/images/solutions/tick-check.svg" className='h-[22px] w-[22px]' alt="" />
                                    <p className='text-base font-semibold text-[#3F3F3F]'>No tech expertise needed to navigate.</p>
                                </div>
                            </div>
                            <hr className='-mx-16' />
                            <div className='py-32 px-22'>
                                <div className='flex gap-10 items-center py-10 px-16 rounded-full bg-[#F7F7F7] border border-[#E6E6E6]'>
                                    <img src="./public/images/solutions/tick-check.svg" className='h-[22px] w-[22px]' alt="" />
                                    <p className='text-base font-semibold text-[#3F3F3F]'>High-speed file uploads, downloads, and sync.</p>
                                </div>
                            </div>
                            <hr className='-mx-16' />
                            <div className='py-32 px-22'>
                                <div className='flex gap-10 items-center py-10 px-16 rounded-full bg-[#F7F7F7] border border-[#E6E6E6]'>
                                    <img src="./public/images/solutions/tick-check.svg" className='h-[22px] w-[22px]' alt="" />
                                    <p className='text-base font-semibold text-[#3F3F3F]'>24/7 customer assistance to resolve issues promptly.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card dark:bg-[#000] !rounded-3xl md:!rounded-tl-none md:!rounded-bl-none mt-32'>
                        <div className="card-header flex items-center justify-center bg-[#3B82F6] rounded-t-3xl md:rounded-tl-none md:rounded-bl-none">
                            <h2 className='text-white text-center text-2xl font-bold p-22 max-w-sm'>Why MyDocUploads
                                is the Best Alternative?</h2>
                        </div>
                        <div className="card-body !py-0">
                            <div className='py-32 px-22'>
                                <div className='flex gap-10 items-center py-10 px-16 rounded-full bg-[#F7F7F7] border border-[#E6E6E6]'>
                                    <img src="./public/images/solutions/cross-check.svg" className='h-[22px] w-[22px]' alt="" />
                                    <p className='text-base font-semibold text-[#3F3F3F]'>High costs for premium security and storage features.</p>
                                </div>
                            </div>
                            <hr className='-mx-16' />
                            <div className='py-32 px-22'>
                                <div className='flex gap-10 items-center py-10 px-16 rounded-full bg-[#F7F7F7] border border-[#E6E6E6]'>
                                    <img src="./public/images/solutions/cross-check.svg" className='h-[22px] w-[22px]' alt="" />
                                    <p className='text-base font-semibold text-[#3F3F3F]'>Limited security features, making sensitive data vulnerable.</p>
                                </div>
                            </div>
                            <hr className='-mx-16' />
                            <div className='py-32 px-22'>
                                <div className='flex gap-10 items-center py-10 px-16 rounded-full bg-[#F7F7F7] border border-[#E6E6E6]'>
                                    <img src="./public/images/solutions/cross-check.svg" className='h-[22px] w-[22px]' alt="" />
                                    <p className='text-base font-semibold text-[#3F3F3F]'>Slow and inefficient file retrieval, impacting productivity.</p>
                                </div>
                            </div>
                            <hr className='-mx-16' />
                            <div className='py-32 px-22'>
                                <div className='flex gap-10 items-center py-10 px-16 rounded-full bg-[#F7F7F7] border border-[#E6E6E6]'>
                                    <img src="./public/images/solutions/cross-check.svg" className='h-[22px] w-[22px]' alt="" />
                                    <p className='text-base font-semibold text-[#3F3F3F]'>Poor customer support, leaving users without assistance.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='grid grid-cols-1 mt-32'>
                    <h1 className="mb-30 mt-30 text-center text-3xl font-normal md:mt-60 md:text-4xl md:font-medium lg:text-6xl lg:font-bold">
                        Cost & Pricing Plans
                    </h1>

                    <p className="text-center lg:px-[18%]">
                        We understand that every user has unique needs, which is why we offer flexible pricing to accommodate  individuals, businesses, and enterprises.
                    </p>
                    <BillingCycleRadio
                        products={query.data?.products}
                        selectedCycle={selectedCycle}
                        onChange={setSelectedCycle}
                        className="mb-40 flex justify-center md:mb-70"
                        size="lg"
                    />

                    <div>
                        <PricingTable
                            selectedCycle={selectedCycle}
                            productLoader="pricingPage"
                        />
                    </div>
                </div>
                <ContactCard
                className="!px-90"
                    title="Get Started with MyDocUploads Today!"
                    subTitle="Don’t risk losing your valuable documents or compromising security with unreliable storage solutions. Make the smart choice with MyDocUploads Secure Cloud Storage and experience the future of secure file management."
                />
            </div>


        </>
    );
}
function HeroSection() {
    return (
        <div className=" bg-[#02AFF4]  px-6 py-50 text-white md:pl-24">
            <div className="container mx-auto grid items-center gap-40 md:grid-cols-2">
                {/* Left Section - Text Content */}
                <div className="text-center md:text-left">
                    <h1 className="text-3xl font-bold leading-tight md:text-5xl">
                        Secure your assets, business documents, and personal data
                    </h1>
                    <p className="text-base font-semibold text-gray-200 dark:text-white mt-32">
                        Store and manage your files in a secure cloud environment. MyDocUploads guarantees privacy and compliance, keeping your data safe from unauthorized access.
                    </p>
                    <div className="mt-32 flex justify-center gap-20  md:justify-start">
                        <button className=" !bg-[#F67A3C] font-semibold text-white text-sm px-32 py-12 w-fit h-fit rounded-full">
                            {' '}
                            Get Started
                        </button>

                        <button className="text-white font-semibold text-sm px-22 py-10 w-fit h-fit rounded-full border border-white">
                            {' '}
                            Compare Plans
                        </button>
                    </div>
                </div>

                {/* Right Section - Image */}
                <div className="flex justify-center">
                    <img
                        src="./public/images/solutions/cloud-storage.svg"
                        alt="File Management"
                        className="w-full"
                    />
                </div>
            </div>
        </div>
    );
}