import {AnimatePresence, m} from 'framer-motion';
import {Fragment} from 'react';
import {opacityAnimation} from '@common/ui/animation/opacity-animation';
import {Skeleton} from '@common/ui/skeleton/skeleton';
import {useProducts} from '@common/billing/pricing-table/use-products';
import {Product} from '@common/billing/product';
import {
  findBestPrice,
  UpsellBillingCycle,
} from '@common/billing/pricing-table/find-best-price';
import {useAuth} from '@common/auth/use-auth';
import clsx from 'clsx';
import {Chip} from '@common/ui/forms/input-field/chip-field/chip';
import {Trans} from '@common/i18n/trans';
import {FormattedPrice} from '@common/i18n/formatted-price';
import {Button} from '@common/ui/buttons/button';
import {Link} from 'react-router-dom';
import {setInLocalStorage} from '@common/utils/hooks/local-storage';
import {ProductFeatureList} from '@common/billing/pricing-table/product-feature-list';

interface PricingTableProps {
  selectedCycle: UpsellBillingCycle;
  className?: string;
  productLoader?: string;
}
export function PricingTable({
  selectedCycle,
  className,
  productLoader,
}: PricingTableProps) {
  const query = useProducts(productLoader);
  return (
    <div
      className={clsx(
        'flex flex-col items-start gap-24 overflow-x-auto overflow-y-visible pb-20 md:flex-row md:justify-center',
        className,
      )}
    >
      <AnimatePresence initial={false} mode="wait">
        {query.data ? (
          <PlanList
            key="plan-list"
            plans={query.data.products}
            selectedPeriod={selectedCycle}
          />
        ) : (
          <SkeletonLoader key="skeleton-loader" />
        )}
      </AnimatePresence>
    </div>
  );
}

interface PlanListProps {
  plans: Product[];
  selectedPeriod: UpsellBillingCycle;
}
function PlanList({plans, selectedPeriod}: PlanListProps) {
  const {isLoggedIn, isSubscribed} = useAuth();
  const filteredPlans = plans.filter(plan => !plan.hidden);
  return (
    <Fragment>
      {filteredPlans.map((plan, index) => {
        const isFirst = index === 0;
        const isLast = index === filteredPlans.length - 1;
        const price = findBestPrice(selectedPeriod, plan.prices);

        let upgradeRoute;
        if (!isLoggedIn) {
          upgradeRoute = `/register?redirectFrom=pricing`;
        }
        if (isSubscribed) {
          upgradeRoute = `/change-plan/${plan.id}/${price?.id}/confirm`;
        }
        if (isLoggedIn && !plan.free) {
          upgradeRoute = `/checkout/${plan.id}/${price?.id}`;
        }

        return (
          <>
            <m.div
              key={plan.id}
              {...opacityAnimation}
              // className="w-full rounded-lg bg-paper shadow-lg md:min-w-240 md:max-w-350"
              className={clsx(
                'w-full rounded-8xl bg-paper  shadow-lg md:min-w-20 md:max-w-125 relative h-[100%]',
                plan.recommended
                  ? 'rounded-tr-3xl rounded-tl-3xl min-h-[730px] rounded-3xl border-5 border-primary dark:border-[#3B82F6]'
                  : 'border py-2 rounded-3xl min-h-[650px] mt-40 ',

                isFirst && 'ml-auto',
                isLast && 'mr-auto',
              )}
            >
              {plan.recommended && (
                <div className="rounded-t-2xl bg-primary py-10 text-center uppercase text-white dark:bg-[#3B82F6]">
                  MOST POPULAR
                </div>
              )}

              <m.div
                key={plan.id}
                {...opacityAnimation}
                className={clsx(
                  'w-full rounded-lg md:min-w-240 md:max-w-370 px-28 py-14',
               
                )}
        
              >
                <div className="mb-14">
                  {/* <Chip
                radius="rounded"
                size="sm"
                className={clsx(
                  'mb-20 w-min',
                  !plan.recommended && 'invisible'
                )}
              >
                <Trans message="Most popular" />
              </Chip> */}
                  <div className="mb-6 text-xl font-semibold">
                    <Trans message={plan.name} />
                  </div>
                  <div className="text-sm text-muted">
                    <Trans message={plan.description} />
                  </div>
                </div>

                <div>
                  {price ? (
                    <FormattedPrice
                      priceClassName="font-bold text-4xl"
                      periodClassName="text-muted text-xs"
                      variant="separateLine"
                      price={price}
                    />
                  ) : (
                    <div className="text-4xl font-bold">
                      <Trans message="Free" />
                    </div>
                  )}
          
                  
              
                  <ProductFeatureList product={plan} />
         <div className='absolute bottom-20 items-center w-[83%]'>
         <div className="mt-50 rounded-full w-full flex items-end">
                  <Button
                      variant="flat"
                      // color="primary"
                      // className="w-full rounded-full items-center flex justify-center dark:bg-[#3B82F6] dark:text-white"
                      className={clsx(
                        "w-full rounded-full items-center flex justify-center text-white",
                        plan.recommended ? "bg-primary dark:bg-[#3B82F6] hover:bg-primary" : "bg-black hover:bg-black"
                      )}
                      size="md"
                      elementType={upgradeRoute ? Link : undefined}
                      disabled={!upgradeRoute}
                      onClick={() => {
                        if (isLoggedIn || !price || !plan) return;
                        setInLocalStorage('be.onboarding.selected', {
                          productId: plan.id,
                          priceId: price.id,
                        });
                      }}
                      to={upgradeRoute}
                    >
                      <ActionButtonText product={plan} />
                    </Button>
                </div>

                {plan.recommended && (
                    <div className="mt-10 text-center rounded-full">
                <p>or <a href='#' className='underline text-black dark:text-white'>contact sales</a></p>
                  </div>
                )}
         </div>
                    
                </div>
              </m.div>
            </m.div>
          </>
        );
      })}
    </Fragment>
  );
}

interface ActionButtonTextProps {
  product: Product;
}
function ActionButtonText({product}: ActionButtonTextProps) {
  const {isLoggedIn} = useAuth();
  if (product.free) {
    // return <Trans message="You're on :plan" values={{plan: product.name}} />;
    return <Trans message="Try for free" />;

  }
  if (product.free && isLoggedIn) {
    // return <Trans message="You're on :plan" values={{plan: product.name}} />;
    return <Trans message="Try for free" />;

  }
  if (product.free || !isLoggedIn) {
    return <Trans message="Select Plan" />;
  }
  return <Trans message="Upgrade" />;
}

function SkeletonLoader() {
  return (
    <Fragment>
      <PlanSkeleton key="skeleton-1" />
      <PlanSkeleton key="skeleton-2" />
      <PlanSkeleton key="skeleton-3" />
    </Fragment>
  );
}

function PlanSkeleton() {
  return (
    <m.div
      {...opacityAnimation}
      className="w-full rounded-lg border px-28 py-90 shadow-lg md:max-w-350"
    >
      <Skeleton className="my-10" />
      <Skeleton className="mb-40" />
      <Skeleton className="mb-40 h-30" />
      <Skeleton className="mb-40 h-40" />
      <Skeleton className="mb-20" />
      <Skeleton />
      <Skeleton />
    </m.div>
  );
}
