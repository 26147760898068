import ContactCard from '@common/ui/contact-card/ContactCard';
import {ListItemBase} from '@common/ui/list/list-item-base';
import {Navbar} from '@common/ui/navigation/navbar/navbar';
import NewFooter from '@common/ui/new-footer/NewFooter';
import React, { useEffect } from 'react';
import {FaCheckCircle} from 'react-icons/fa';
import {FaCircleCheck} from 'react-icons/fa6';
import AddBar from './AddBar';

const AboutUs = () => {

  useEffect(()=>(
window.scrollTo(0,0)
  ),[])
  return (
    <>
      <div className="container mx-auto">
        <Navbar
          color="transparent"
          className="flex-shrink-0"
          menuPosition="homepage-navbar"
        />
      </div>
      <AddBar/>

      <div className="container mt-10 mx-auto">

        <About />
      </div>
      <NewFooter />
    </>
  );
};

export default AboutUs;

function About() {
  const sections = [
    {
      title: "Built for ",
      title2: "Security & Privacy",
      text: "Your data security is our priority. MyDocUploads uses end-to-end encryption to keep your files safe from unauthorized access. Unlike traditional cloud storage providers, we ensure that only you have control over your documents.",
      image: "./public/images/about-us/securityprivacy.svg",
      reverse: false,
      listItems: [
        {
          title: "Zero-Knowledge Encryption",
          description: "We can’t access your files. Only you have the keys",
        },
        {
          title: "Private File Sharing",
          description:
            "Share files with confidence using password protection and expiration settings",
        },
        {
          title: "100% Data Ownership",
          description:
            "You control who sees, edits, and downloads your documents",
        },
      ],
    },
    {
      title: "Effortless ",
      title2: "Collaboration",
      text: "Whether you're working solo or with a team, MyDocUploads makes collaboration easy. Share documents instantly, edit in real time, and manage permissions effortlessly—all while keeping your data private and secure.",
      image: "./public/images/about-us/effortless.svg",
      reverse: true,
      listItems: [
        {
          title: "Real-Time Syncing",
          description: "Access the latest version of your files anytime, anywhere",
        },
        {
          title: "Flexible Access Controls",
          description: "Decide who can view, edit, or download your documents",
        },
      ],
    },
    {
      title: "Why Choose ",
      title2: "MyDocUploads?",
      text: "",
      image: "./public/images/about-us/whydocsupload.svg",
      reverse: false,
      listItems: [
        {
          title: "Secure & Private",
          description: "Your files are encrypted, ensuring only you can access them",
        },
        {
          title: "Easy to Use",
          description: "A clean, intuitive interface for seamless file management",
        },
        {
          title: "Fast & Reliable",
          description: "Upload, access, and share documents in seconds",
        },
        {
          title: "Anywhere Access",
          description:
            "Work across desktop, mobile, and web with full synchronization",
        },
      ],
    },


 

  ];
  

  return (
    <section className="lg:py-[10px] ">
      <div className="container mx-auto">
        <div className="relative flex h-[500px] w-full items-center rounded-xl shadow-md lg:h-[600px]">
          <div
            className="absolute inset-0 rounded-xl bg-cover bg-center"
            style={{
              backgroundImage: "url('./public/images/about-us/about-us.png')",
            }}
          >
            {/* <div className="absolute inset-0 rounded-xl bg-gradient-to-r from-white to-transparent dark:bg-gradient-to-r from-black to-transparent"></div> */}
            <div className="absolute inset-0 rounded-xl bg-gradient-to-r from-white to-transparent dark:from-black dark:to-transparent"></div>

          </div>

          <div className="relative z-10 max-w-2xl px-6 lg:px-36">
            <p className="text-[#F67A3C] text-sm font-semibold uppercase">
              What We Offer?
            </p>
            <h1 className="text-3xl font-bold leading-tight md:text-5xl lg:text-6xl">
              Your <span className="text-primary dark:text-[#3b82f6]">Secure & Seamless</span>{' '}
              Document Sharing Solution
            </h1>
          </div>
        </div>

        <div>
          {sections.map((section, index) => (
            <div
              key={index}
              className="flex flex-col items-center justify-center gap-5 md:flex-row lg:pt-50"
            >
              <div
                className={`w-full md:w-1/2 ${index % 2 == 0 ? 'md:order-2' : ''}`}
              >
                <img
                  src={section.image}
                  width="100%"
                  className="mt-20 md:mt-0"
                  alt={section.title}
                />
              </div>

              <div className="flex w-full flex-col items-start justify-center md:w-1/2 lg:px-50">
                <h1 className="font-bold md:leading-[48px] lg:text-6xl dark:text-white">
                  {section.title}{' '}
                  <span className="text-primary lg:text-6xl dark:text-[#3b82f6]">
                    {section.title2}
                  </span>
                </h1>
                <p className="mt-10 text-sm lg:text-[#475569] dark:text-white">
                  {section.text}
                </p>

                <ul className="text-start">
                  {section.listItems.map((item, index) => (
                    <li key={index} className="mt-10 flex items-start gap-8">
                      <img
                        src="./public/images/about-us/correct-icon.png"
                        className="mt-3 w-[1.2rem]"
                      />
                      <span className="text-sm font-semibold text-[#303030B2] dark:text-white">
                        {item.title}:
                        <span className="text-sm font-normal lg:text-[#475569] dark:text-white">
                          {item.description}
                        </span>
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>

<ContactCard/>
        {/* <div className="item-center grid justify-center gap-5 md:grid-cols-2 lg:pt-50">
          <div>
            <img
              src="./public/images/about-us/story.svg"
              width="100%"
              className="mt-20 md:mt-0"
              alt=""
            />
          </div>
          <div className="item-center flex flex-col justify-center lg:px-50">
            <h1 className="text-bold md:leading-[48px] lg:text-[40px]">
              Our <span className="text-[#014CDA]"> Story</span>
            </h1>
            <p className="mt-10 text-sm lg:text-[#475569]">
              Founded by a team of tech enthusiasts with a passion for
              innovation, MyDocUploads has grown into a trusted platform used by
              professionals and businesses worldwide. We understand the
              challenges of modern document management and are committed to
              delivering solutions that meet the highest standards of quality
              and security.
            </p>
          </div>
        </div>

        <div className="grid gap-5 pt-50 md:grid-cols-2">
          <div className="item-center flex flex-col justify-center lg:px-50">
            <h1 className="text-bold md:leading-[48px] lg:text-[40px]">
              Our <span className="text-[#014CDA]">Vision</span>
            </h1>
            <p className="mt-10 text-sm lg:text-[#475569]">
              We envision a world where document management is effortless,
              secure, and accessible to everyone. By continuously improving our
              platform and listening to our users' needs, we strive to stay at
              the forefront of the industry and provide the best possible
              experience for our customers.
            </p>
          </div>
          <div>
            <img
              src="./public/images/about-us/vision.svg"
              width="100%"
              alt=""
            />
          </div>
        </div> */}
      </div>
    </section>
  );
}
