import React from 'react';

const PricingCompareTable = () => {
  const plans = [
    {
      name: 'Starter',
      price: '$0/per year',
      buttonText: 'Start Free',
      storage: '10 GB',
      features: [false, true, true, true, false, false, false, false, '-'],
    },
    {
      name: 'Professional',
      price: '$86/per year',
      buttonText: 'Choose Plan',
      storage: '2 TB',
      features: [true, true, true, true, true, true, true, '3'],
    },
    {
      name: 'Business',
      price: '$182/per year',
      buttonText: 'Choose Plan',
      storage: '10 TB',
      features: [true, true, true, true, true, true, true, '10'],
    },
  ];

  const featureList = [
    'Secure & Fast Cloud Servers',
    'Two Factor Authentication (2FA)',
    '256-bit AES and SSL/TLS encryption',
    'Advanced Link Share Controls',
    'Priority Email Support',
    'Preview Stored Documents without Downloading',
    'Secure Message - Limited',
    'Add up to 3 Secure Workspaces',
  ];

  return (
    <div className="container mx-auto">
      <h2 className="my-30 text-center text-4xl font-bold">
        Compare Our Plans
      </h2>
      <div className="overflow-x-auto">
        <table className="border-gray-200 min-w-full rounded-xl border-2 bg-white outline-none">
          <thead className="">
            <tr className="bg-gray-100 border-b">
              <th className="p-20 text-left">Plan Features</th>
              {plans.map(plan => (
                <th key={plan.name} className="px-0">
                  {plan.name}
                </th>
              ))}
            </tr>
            <tr className="bg-gray-100 border-b">
              <th className="px-20 py-14 text-left text-sm font-semibold">
                Features
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-b bg-[#F1F6FF] dark:bg-black">
              <td className="px-20 py-14">10GB of Secure Storage</td>
              {plans.map(plan => (
                <td key={plan.name} className="px-20 py-4 text-center">
                  {plan.storage}
                </td>
              ))}
            </tr>
            {featureList.map((feature, index) => (
              <tr
                key={index}
                className={`border-b ${index % 2 === 1 ? 'bg-[#F1F6FF] dark:bg-black' : ''}`}
              >
                <td className="px-20 py-14">{feature}</td>
                {plans.map((plan, planIndex) => (
                  <td key={planIndex} className="px-20 py-4 text-center">
                    {typeof plan.features[index] === 'boolean' ? (
                      <img
                        src={
                          plan.features[index]
                            ? './public/images/about-us/correct-icon.png'
                            : './public/images/about-us/cross-icon.svg'
                        }
                        alt={plan.features[index] ? 'Correct' : 'Wrong'}
                        className="mx-auto h-auto w-16"
                      />
                    ) : (
                      plan.features[index]
                    )}
                  </td>
                ))}
              </tr>
            ))}
            <tr>
              <td></td>
              {plans.map(plan => (
                <td key={plan.name} className="py-20 text-center">
                  <div className="text-lg font-semibold">{plan.price}</div>
                  <button className="mt-2 rounded-full bg-primary px-10 py-8 text-white dark:bg-[#3B82F6]">
                    {plan.buttonText}
                  </button>
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PricingCompareTable;
