import Testimonials from '@app/landing/Testimonials';
import {Footer} from '@common/ui/footer/footer';
import {Navbar} from '@common/ui/navigation/navbar/navbar';
import NewFooter from '@common/ui/new-footer/NewFooter';
import {FaRegCheckCircle} from 'react-icons/fa';
import {Link} from 'react-router-dom';
import {Tabs, TabList, Tab, TabPanel} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {FAQSection} from '../Faq';
import ContactCard from '@common/ui/contact-card/ContactCard';
import AddBar from '../AddBar';
const Solutions = () => {
  return (
    <>
      <div className="container mx-auto">
        <Navbar
          color="transparent"
          className="flex-shrink-0"
          menuPosition="homepage-navbar"
        />
      </div>
      <AddBar/>
      <SolutionsPage />
      <NewFooter />
    </>
  );
};

export default Solutions;

function SolutionsPage() {
  return (
    <>
      <HeroSection />

      <div className="container mx-auto ">
        <OurSolutions />

        <div className="my-60 rounded-lg bg-[#00348E] p-6 text-white">
          <div className="flex  items-center justify-between gap-50 px-[1%] lg:px-[5%] py-20 lg:py-50">
            <h2 className="mb-4 text-center text-xl lg:text-3xl font-bold">
              Enjoy it all for free! No Extra Cost
            </h2>
            <div className="grid grid-cols-1 gap-24 text-sm md:grid-cols-2">
              <div className="flex items-center gap-2">
                <FaRegCheckCircle className="text-white" />
                <span className='text-xs lg:text-sm' >10GB of Secure Storage</span>
              </div>
              <div className="flex items-center gap-2">
                <FaRegCheckCircle className="text-white" />
                <span className='text-xs lg:text-sm'>Advanced Link Share Controls</span>
              </div>
              <div className="flex items-center gap-2">
                <FaRegCheckCircle className="text-white" />

                <span className='text-xs lg:text-sm'>Two Factor Authentication (2FA)</span>
              </div>
              <div className="flex items-center gap-2">
                <FaRegCheckCircle className="text-white" />

                <span className='text-xs lg:text-sm'>Secure & Fast Cloud Servers</span>
              </div>
            </div>
          </div>
        </div>

        <h1 className="mb-30 mt-30 text-center text-3xl font-normal md:mt-60 md:text-4xl md:font-medium lg:text-4xl lg:font-bold">
          Trusted by millions of users globally.
        </h1>

        <Testimonials />

        <FAQSection />
        <ContactCard />
      </div>

      {/* <section className=" lg:py-[100px] ">
      <div className="">
        <div className="flex flex-col">
          <h1 className='lg:text-7xl md:text-[40px]  fw-[700] text-center'>solutions Discover What You Can Do with Our Platform's <span className='text-[#014CDA]'>Powerful Features</span></h1>
        </div>
      </div>


      <Tabs className='features-tabs mt-20'>
        <TabList className='flex flex-col md:flex-row items-center md:items-center justify-center'>
          <Tab>Secure messaging</Tab>
          <Tab>Anywhere Access</Tab>
          <Tab>Secure Collaboration</Tab>
          <Tab>Professional File Transfers</Tab>
          <Tab>Security & Compliance</Tab>
        </TabList>

        <TabPanel>
          <SecureMessaging />
        </TabPanel>
        <TabPanel>
          <AnywhereAccess />
        </TabPanel>
        <TabPanel>
          <SecureCollaboration />
        </TabPanel>
        <TabPanel>
          <ProfessionalFileTransfers />
        </TabPanel>
        <TabPanel>
          <SecurityCompliance />
        </TabPanel>
      </Tabs>
    </section> */}
    </>
  );
}

function HeroSection() {
  return (
    <div className="bg-gradient-to-r from-[#0948B3] to-[#1869F1] px-6 py-80 text-white md:pl-24">
      <div className="container mx-auto grid items-center gap-20 md:grid-cols-2">
        {/* Left Section - Text Content */}
        <div className="text-center md:text-left">
          <h1 className="text-3xl font-bold leading-tight md:text-5xl">
            Effortless and Secure <br />
            <span className="text-white">File Management</span>
          </h1>
          <p className="text-md text-gray-200 mt-6">
            MyDocUploads simplifies cloud storage and collaboration with
            enterprise-grade security. Whether you're sharing files, working
            with a team, or accessing data remotely, MyDocUploads ensures your
            documents stay protected and accessible anytime, anywhere.
          </p>
          <div className="mt-8 flex flex-col justify-center gap-4 md:flex-row md:justify-start">
            {/* <button className="bg-orange-500 hover:bg-orange-600 px-6 py-3 text-white font-semibold rounded-lg shadow-md">
                Get Started
              </button> */}
            <button className="btn-orange-colored mt-20  w-[120px]">
              {' '}
              Get Started
            </button>

            <button className="btn-white mt-20  w-[140px]">
              {' '}
              Compare Plans
            </button>
          </div>
        </div>

        {/* Right Section - Image */}
        <div className="flex justify-center">
          <img
            src="./public/images/solutions/HeroSideImage.svg"
            alt="File Management"
            className="w-full"
          />
        </div>
      </div>
    </div>
  );
}

function OurSolutions() {
  const solutions = [
    {
      title: 'Secure File Sharing',
      description:
        'Share files confidently with end-to-end encryption, ensuring only authorized users have access. Set permissions, password-protect sensitive data, and track file activity with ease.',
      icon: './public/images/solutions/sol1.png',
    },
    {
      title: 'Team Collaboration',
      description:
        'Enhance productivity with real-time collaboration. Share files, assign roles, and streamline workflows—all while keeping your data secure.',
      icon: './public/images/solutions/sol2.png',
    },
    {
      title: 'Secure Cloud Storage',
      description:
        'Store and manage your files in a secure cloud environment. MyDocUploads guarantees privacy and compliance, keeping your data safe from unauthorized access.',
      icon: './public/images/solutions/sol3.png',
    },
    {
      title: 'Work Remotely',
      description:
        "Access your files anytime, from any device. Whether you're in the office or on the go, MyDocUploads keeps your workflow uninterrupted and secure.",
      icon: './public/images/solutions/sol4.png',
    },
  ];

  return (
    <section className="bg-white px-6 py-16">
      <h2 className="text-gray-900 text-start text-4xl font-bold lg:max-w-6xl">
        Our <span className="text-primary">Solutions</span>
      </h2>
      <div className="mx-auto mt-8 grid gap-26  md:grid-cols-2">
        {solutions.map((solution, index) => (
          <div
            key={index}
            className="items-start gap-4 rounded-xl bg-white p-26 shadow-md"
          >
            {/* <span className="text-4xl">{solution.icon}</span> */}

            <img src={solution.icon} alt="solution" className="w-50" />

            <div>
              <h3 className="text-gray-800 text-xl font-semibold">
                {solution.title}
              </h3>
              <p className="text-gray-600 mt-2">{solution.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

function SecureMessaging() {
  return (
    <>
      <div className="grid gap-5 pt-50 md:grid-cols-2">
        <div className="item-center flex flex-col justify-center lg:px-50">
          <h1 className="text-bold md:leading-[48px] lg:text-[40px]">
            Secure Message Service - Send{' '}
            <span className="text-[#014CDA]">Encrypted Messages & Files</span>{' '}
            with Confidence
          </h1>
          <p className="mt-10 text-sm lg:text-[#475569]">
            In today's digital age, security and privacy are more critical than
            ever. At MyDocUploads, we understand the need to protect sensitive
            information, which is why we've developed our Secure Message
            service. This service allows you to send encrypted messages and file
            attachments to anyone, ensuring your data is safe and confidential.
          </p>
        </div>
        <div>
          <img
            src="./public/images/features/secure-messaging.svg"
            width="100%"
            alt=""
          />
        </div>
      </div>

      <div className="item-center grid justify-center gap-5 md:grid-cols-2 lg:pt-50">
        <div>
          <img
            src="./public/images/features/feature-1.svg"
            width="100%"
            className="mt-20 md:mt-0"
            alt=""
          />
        </div>
        <div className="item-center flex flex-col justify-center lg:px-50">
          <p className="text-sm text-[#F67A3C]">End-to-End Encryption</p>
          <h1 className="text-bold md:leading-[48px] lg:text-[40px]">
            Why Choose Our{' '}
            <span className="text-[#014CDA]">Secure Message</span> Service?
          </h1>
          <p className="mt-10 text-sm lg:text-[#475569]">
            Our Secure Message service uses advanced encryption technology to
            protect your messages and file attachments. This ensures that only
            the intended recipient can access the information, keeping your data
            safe from prying eyes.
          </p>
        </div>
      </div>

      <div className="item-center grid justify-center gap-5 pt-50 md:grid-cols-2">
        <div className="item-center flex flex-col justify-center lg:px-50">
          <p className="text-sm text-[#F67A3C]">Simple and User-Friendly</p>
          <h1 className="text-bold leading-[48px] lg:text-[40px]">
            How It <span className="text-[#014CDA]">Works</span>?
          </h1>
          <p className="text-sm md:mt-10 lg:text-[#475569]">
            Sending a secure message with MyDocUploads is straightforward.
            Simply compose your message, attach any necessary files, and send it
            to your recipient. Our platform handles the encryption process
            automatically.
          </p>
        </div>
        <div>
          <img
            src="./public/images/features/how-work.svg"
            width="100%"
            className="mt-10"
            alt=""
          />
        </div>
      </div>

      <div className="grid-cols-1/2 item-center mt-50 grid justify-center gap-5 md:pt-50">
        <h1 className="text-bold text-center text-[30px] leading-[48px] lg:text-[40px]">
          Key <span className="text-[#014CDA]">Features</span> of Secure Message
        </h1>
        <div className="relative md:mt-50">
          <div className="top-0 bg-white md:absolute md:m-20">
            <div className="card mb-20 rounded-[15px] p-10 md:w-[25rem]">
              <h4 className="text-[#014CDA]">Automatic Data Purge</h4>
              <p className="mt-2 text-sm lg:text-[#475569]">
                Sending a secure message with MyDocUploads is straightforward.
                Simply compose your message, attach any necessary files, and
                send it to your recipient. Our platform handles the encryption
                process automatically.
              </p>
            </div>
          </div>
          <img
            src="./public/images/features/message-feature.svg"
            width="100%"
            alt=""
          />
          <div className="bottom-0 right-0 m-20 bg-white md:absolute">
            <div className="card rounded-[15px] p-10 md:w-[25rem]">
              <h4 className="text-[#014CDA]">Link Expiry Options</h4>
              <p className="mt-2 text-sm lg:text-[#475569]">
                Customize how long your link stays active. Choose from various
                expiry options to fit your needs, providing flexibility and
                added security.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="md:grid-cols-1/2 item-center grid justify-center pt-50 md:m-50">
        <h1 className="text-bold text-center text-[30px] leading-[48px] lg:text-[40px]">
          <span className="text-[#014CDA]">Benefits</span> of Using Secure
          Message
        </h1>
        <div className="item-center grid justify-center gap-30 md:grid-cols-2 md:pt-50">
          <div className="card p-20">
            <h4 className="text-bold text-[30px] text-[#383838]">
              Peace of Mind
            </h4>
            <p className="mt-10 text-sm lg:text-[#475569]">
              With our Secure Message service, you can rest assured that your
              sensitive information is protected. Whether you're sharing
              personal details, business documents, or confidential
              communications, our service keeps your data secure.
            </p>
            <img
              src="./public/images/features/peace-mind.svg"
              width="100%"
              alt=""
            />
          </div>
          <div className="card p-20">
            <h4 className="text-bold text-[30px] text-[#383838]">
              Convenience
            </h4>
            <p className="mt-10 text-sm lg:text-[#475569]">
              No need for complex setup or technical knowledge. Our platform is
              designed to be user-friendly, making it easy for anyone to send
              encrypted messages and files.
            </p>
            <img
              src="./public/images/features/convience.svg"
              width="100%"
              alt=""
            />
          </div>
        </div>

        <div className="card mt-100 p-20">
          <h1 className="text-bold text-center text-[30px] leading-[48px] lg:text-[40px]">
            <span className="text-[#014CDA]">Get Started</span> Today!
          </h1>
          <p className="mt-10 text-center text-sm lg:text-[#475569]">
            Protect your communications with our Secure Message service. Sign up
            now to start sending encrypted messages and file attachments with
            confidence. Experience the peace of mind that comes with knowing
            your information is{' '}
            <span className="font-[700] italic">secure</span> and{' '}
            <span className="font-[700] italic">private</span>.
          </p>
          <p className="mt-10 text-center text-sm lg:text-[#475569]">
            Sign up for a{' '}
            <span className="font-[700] italic">FREE Basic Account</span> and
            start using Secure Message right away!
          </p>
          <Link to="/register" className="m-auto">
            <button className="btn-login mt-20  w-[150px]">Sign Up Now</button>
          </Link>
        </div>
      </div>
    </>
  );
}

function AnywhereAccess() {
  return (
    <>
      <div className="grid gap-5 pt-50 md:grid-cols-2">
        <div className="item-center flex flex-col justify-center lg:px-50">
          <p className="text-sm text-[#F67A3C]">
            Your Documents, Available Whenever and Wherever You Need Them
          </p>
          <h1 className="text-bold md:leading-[48px] lg:text-[40px]">
            Access Your Files{' '}
            <span className="text-[#014CDA]">Anywhere, Anytime</span>
          </h1>
          <p className="mt-10 text-sm lg:text-[#475569]">
            With MyDocUploads, your important documents are always at your
            fingertips. Whether you're at the office, at home, or on the go, our
            platform ensures you can access your files from any device, 24/7.
            Say goodbye to the hassle of physical storage and enjoy the
            convenience of cloud-based document management.
          </p>
        </div>
        <div>
          <img
            src="./public/images/features/anywhere-access.svg"
            width="100%"
            alt=""
          />
        </div>
      </div>

      <div className="item-center grid justify-center gap-5 md:grid-cols-2 lg:pt-50">
        <div>
          <img
            src="./public/images/features/key-features.svg"
            width="100%"
            className="mt-20 md:mt-0"
            alt=""
          />
        </div>
        <div className="item-center flex flex-col justify-center lg:px-50">
          <h1 className="text-bold md:leading-[48px] lg:text-[40px]">
            Benefits of{' '}
            <span className="text-[#014CDA]">Anywhere, Anytime Access</span>
          </h1>
          <ul className="mt-5">
            <li className="mt-10">
              <span className="font-bold text-[#F67A3C]">Flexibility:</span>{' '}
              Work from any location without being tied to a single device or
              physical files.
            </li>
            <li className="mt-10">
              <span className="font-bold text-[#F67A3C]">Productivity:</span>{' '}
              Quickly find and retrieve the documents you need, reducing
              downtime and increasing efficiency.
            </li>
            <li className="mt-10">
              <span className="font-bold text-[#F67A3C]">Backup:</span> Your
              files are stored securely in the cloud, providing automatic backup
              and easy recovery in case of device loss or failure.
            </li>
          </ul>
        </div>
      </div>

      <div className="item-center grid justify-center gap-5 pt-50 md:grid-cols-2">
        <div className="item-center flex flex-col justify-center lg:px-50">
          <h1 className="text-bold md:leading-[48px] lg:text-[40px]">
            How <span className="text-[#014CDA]"> It Works?</span>
          </h1>
          <p className="mt-10 text-sm lg:text-[#475569]">
            MyDocUploads uses cutting-edge cloud technology to store your
            documents securely online. Simply upload your files to our platform,
            and access them using our web interface or mobile app. Whether you
            need to review a contract, share a report, or work on a project,
            MyDocUploads makes it easy to stay connected with your documents.
          </p>
        </div>
        <div>
          <img
            src="./public/images/features/how-work.svg"
            width="100%"
            className="mt-10"
            alt=""
          />
        </div>
      </div>
    </>
  );
}

function SecureCollaboration() {
  return (
    <>
      <div className="grid gap-5 pt-50 md:grid-cols-2">
        <div className="item-center flex flex-col justify-center lg:px-50">
          <p className="text-sm text-[#F67A3C]">
            Seamless Collaboration and Secure File Sharing
          </p>
          <h1 className="text-bold md:leading-[48px] lg:text-[40px]">
            <span className="text-[#014CDA]">
              Share and Collaborate Securely{' '}
            </span>{' '}
            with Anyone
          </h1>
          <p className="mt-10 text-sm lg:text-[#475569]">
            Enhance your team's productivity with MyDocUploads. Share files
            securely with colleagues, clients, and partners. Our platform
            supports real-time collaboration, making it easy to work together,
            provide feedback, and achieve your goals faster. Experience the ease
            of secure file sharing without compromising on data protection.
          </p>
        </div>
        <div>
          <img
            src="./public/images/features/secure-collaboration.svg"
            width="100%"
            alt=""
          />
        </div>
      </div>

      <div className="item-center grid justify-center gap-5 md:grid-cols-2 lg:pt-50">
        <div>
          <img
            src="./public/images/features/key-features.svg"
            width="100%"
            className="mt-20 md:mt-0"
            alt=""
          />
        </div>
        <div className="item-center flex flex-col justify-center lg:px-50">
          <h1 className="text-bold md:leading-[48px] lg:text-[40px]">
            <span className="text-[#014CDA]">Key Features </span> of Secure
            Collaboration
          </h1>
          <ul className="mt-5">
            <li className="mt-10">
              <span className="font-bold text-[#F67A3C]">
                Real-Time Editing:
              </span>{' '}
              Collaborate on documents in real-time, ensuring everyone is on the
              same page.
            </li>
            <li className="mt-10">
              <span className="font-bold text-[#F67A3C]">Version Control:</span>{' '}
              Track changes and access previous versions to avoid conflicts and
              maintain document integrity.
            </li>
            <li className="mt-10">
              <span className="font-bold text-[#F67A3C]">Access Controls:</span>{' '}
              Set permissions and control who can view, edit, or share your
              files to ensure data security.
            </li>
          </ul>
        </div>
      </div>

      <div className="item-center grid justify-center gap-5 pt-50 md:grid-cols-2">
        <div className="item-center flex flex-col justify-center lg:px-50">
          <h1 className="text-bold md:leading-[48px] lg:text-[40px]">
            Benefits of{' '}
            <span className="text-[#014CDA]"> Collaboration Made Easy</span>
          </h1>
          <p className="mt-10 text-sm lg:text-[#475569]">
            MyDocUploads integrates seamlessly with popular productivity tools
            like Microsoft Office and Google Workspace, allowing you to edit
            documents directly from our platform. Share files via secure links
            or invite collaborators via email. Our intuitive interface ensures
            everyone can collaborate effectively, regardless of their technical
            expertise.
          </p>
        </div>
        <div>
          <img
            src="./public/images/features/benefits.svg"
            width="100%"
            className="mt-10"
            alt=""
          />
        </div>
      </div>
    </>
  );
}

function ProfessionalFileTransfers() {
  return (
    <>
      <div className="grid gap-5 pt-50 md:grid-cols-2">
        <div className="item-center flex flex-col justify-center lg:px-50">
          <p className="text-sm text-[#F67A3C]">
            Professional File Transfers Made Simple
          </p>
          <h1 className="text-bold md:leading-[48px] lg:text-[40px]">
            Send and Receive Files
            <span className="text-[#014CDA]">Professionally</span>
          </h1>
          <p className="mt-10 text-sm lg:text-[#475569]">
            Elevate your business communication with MyDocUploads. Send and
            receive large files with confidence, knowing your data is secure and
            the process is streamlined. Our professional-grade file transfer
            service ensures your documents reach their destination safely and
            efficiently, reflecting the professionalism of your brand.
          </p>
        </div>
        <div>
          <img
            src="./public/images/features/professional-file-transfers.svg"
            width="100%"
            alt=""
          />
        </div>
      </div>

      <div className="item-center grid justify-center gap-5 md:grid-cols-2 lg:pt-50">
        <div>
          <img
            src="./public/images/features/key-features.svg"
            width="100%"
            className="mt-20 md:mt-0"
            alt=""
          />
        </div>
        <div className="item-center flex flex-col justify-center lg:px-50">
          <h1 className="text-bold md:leading-[48px] lg:text-[40px]">
            <span className="text-[#014CDA]">Benefits </span> of Professional
            File Transfers
          </h1>
          <ul className="mt-5">
            <li className="mt-10">
              <span className="font-bold text-[#F67A3C]">
                Large File Support:{' '}
              </span>{' '}
              Easily send and receive files of any size, avoiding email
              attachment limits.
            </li>
            <li className="mt-10">
              <span className="font-bold text-[#F67A3C]">
                Secure Transmission:{' '}
              </span>{' '}
              Our platform uses encryption to protect your files during
              transfer, ensuring they remain confidential.
            </li>
            <li className="mt-10">
              <span className="font-bold text-[#F67A3C]">
                Tracking and Notifications:{' '}
              </span>{' '}
              Receive notifications when files are delivered and accessed,
              giving you complete visibility and control.
            </li>
          </ul>
        </div>
      </div>

      <div className="item-center grid justify-center gap-5 pt-50 md:grid-cols-2">
        <div className="item-center flex flex-col justify-center lg:px-50">
          <h1 className="text-bold md:leading-[48px] lg:text-[40px]">
            How <span className="text-[#014CDA]">It Works?</span>
          </h1>
          <p className="mt-10 text-sm lg:text-[#475569]">
            Sending files with MyDocUploads is simple and secure. Upload your
            file, enter the recipient's email address, and click send. The
            recipient will receive a secure link to download the file. For added
            security, you can set expiration dates and password-protect your
            links. Whether you're sending a large presentation or a confidential
            report, MyDocUploads ensures your files are delivered safely and
            professionally.
          </p>
        </div>
        <div>
          <img
            src="./public/images/features/how-work.svg"
            width="100%"
            className="mt-10"
            alt=""
          />
        </div>
      </div>
    </>
  );
}

function SecurityCompliance() {
  return (
    <>
      <div className="grid gap-5 pt-50 md:grid-cols-2">
        <div className="item-center flex flex-col justify-center lg:px-50">
          <p className="text-sm text-[#F67A3C]">
            Advanced Security Features to Protect Your Data
          </p>
          <h1 className="text-bold md:leading-[48px] lg:text-[40px]">
            Security & Compliance Built for
            <span className="text-[#014CDA]">Peace of Mind</span>
          </h1>
          <p className="mt-10 text-sm lg:text-[#475569]">
            At MyDocUploads, we prioritize your data's security and compliance.
            Our platform is designed with state-of-the-art encryption, regular
            security audits, and strict compliance with industry standards. Rest
            easy knowing your sensitive information is protected against
            unauthorized access and breaches.
          </p>
        </div>
        <div>
          <img
            src="./public/images/features/security-compliance.svg"
            width="100%"
            alt=""
          />
        </div>
      </div>

      <div className="item-center grid justify-center gap-5 md:grid-cols-2 lg:pt-50">
        <div>
          <img
            src="./public/images/features/key-features.svg"
            width="100%"
            className="mt-20 md:mt-0"
            alt=""
          />
        </div>
        <div className="item-center flex flex-col justify-center lg:px-50">
          <h1 className="text-bold md:leading-[48px] lg:text-[40px]">
            Comprehensive{' '}
            <span className="text-[#014CDA]">Security Measures </span>
          </h1>
          <ul className="mt-5">
            <li className="mt-10">
              <span className="font-bold text-[#F67A3C]">Encryption: </span> All
              data is encrypted both in transit and at rest, ensuring it remains
              secure.
            </li>
            <li className="mt-10">
              <span className="font-bold text-[#F67A3C]">
                Access Controls:{' '}
              </span>{' '}
              Manage user permissions to control who can access and edit your
              documents.
            </li>
            <li className="mt-10">
              <span className="font-bold text-[#F67A3C]">Audit Trails: </span>{' '}
              Maintain detailed logs of all file access and activity for
              compliance and security purposes.
            </li>
          </ul>
        </div>
      </div>

      <div className="item-center grid justify-center gap-5 pt-50 md:grid-cols-2">
        <div className="item-center flex flex-col justify-center lg:px-50">
          <h1 className="text-bold md:leading-[48px] lg:text-[40px]">
            Compliance You <span className="text-[#014CDA]">Can Trust</span>
          </h1>
          <p className="mt-10 text-sm lg:text-[#475569]">
            MyDocUploads complies with industry-leading standards and
            regulations, including GDPR, HIPAA, and SOC 2. Our commitment to
            compliance ensures that your data is handled with the utmost care
            and in accordance with all relevant laws. Trust MyDocUploads to
            provide a secure environment for your most sensitive documents.
          </p>
        </div>
        <div>
          <img
            src="./public/images/features/compliance.svg"
            width="100%"
            className="mt-10"
            alt=""
          />
        </div>
      </div>
    </>
  );
}
