import React, { forwardRef, Fragment, useState } from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { MenuConfig, MenuItemConfig } from '../core/settings/settings';
import { createSvgIconFromTree } from '../icons/create-svg-icon';
import { Orientation } from '../ui/forms/orientation';
import { useCustomMenu } from './use-custom-menu';
import { Trans } from '../i18n/trans';
import { IconSize } from '@common/icons/svg-icon';

type MatchDescendants = undefined | boolean | ((to: string) => boolean);

interface CustomMenuProps {
  className?: string;
  matchDescendants?: MatchDescendants;
  iconClassName?: string;
  iconSize?: IconSize;
  itemClassName?:
  | string
  | ((props: { isActive: boolean; item: MenuItemConfig }) => string | undefined);
  gap?: string;
  menu?: string | MenuConfig;
  children?: (menuItem: MenuItemConfig) => React.ReactElement;
  orientation?: Orientation;
  onlyShowIcons?: boolean;
  unstyled?: boolean;
}

export function CustomMenu({
  className,
  iconClassName,
  itemClassName,
  gap = 'gap-30',
  menu: menuOrPosition,
  orientation = 'horizontal',
  children,
  matchDescendants,
  onlyShowIcons,
  iconSize,
  unstyled = false,
}: CustomMenuProps) {
  const menu = useCustomMenu(menuOrPosition);
  if (!menu) return null;

  return (
    <div
      className={clsx(
        'flex',
        'ml-auto',
        'navbar-items',
        gap,
        orientation === 'vertical' ? 'flex-col items-start' : 'items-center',
        className
      )}
      data-menu-id={menu.id}
    >
      {menu.items.map(item => {
        if (children) return children(item);

        return (
          <CustomMenuItem
            key={item.id}
            item={item}
            unstyled={unstyled}
            onlyShowIcon={onlyShowIcons}
            matchDescendants={matchDescendants}
            iconClassName={iconClassName}
            iconSize={iconSize}
            className={props =>
              typeof itemClassName === 'function'
                ? itemClassName({ ...props, item })
                : itemClassName
            }
          />
        );
      })}
    </div>
  );
}

interface MenuItemProps {
  item: MenuItemConfig;
  iconClassName?: string;
  className?: (props: { isActive: boolean }) => string | undefined;
  matchDescendants?: MatchDescendants;
  onlyShowIcon?: boolean;
  iconSize?: IconSize;
  unstyled?: boolean;
}

export const CustomMenuItem = forwardRef<HTMLAnchorElement, MenuItemProps>(
  (
    {
      item,
      className,
      matchDescendants,
      unstyled,
      onlyShowIcon,
      iconClassName,
      iconSize = 'sm',
      ...linkProps
    },
    ref
  ) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const label = <Trans message={item.label} />;
    const Icon = item.icon && createSvgIconFromTree(item.icon);
    const content = (
      <Fragment>
        {Icon && <Icon size={iconSize} className={iconClassName} />}
        {(!Icon || !onlyShowIcon) && label}
      </Fragment>
    );

    const baseClassName = !unstyled && 'block whitespace-nowrap flex items-center justify-start gap-1';
    const focusClassNames = !unstyled && 'outline-none focus-visible:ring-2';

    return (
      <div
        className="relative group"
        onMouseEnter={() => setDropdownOpen(true)}
        onMouseLeave={() => setDropdownOpen(false)}
      >
        <NavLink
          end={
            typeof matchDescendants === 'function'
              ? matchDescendants(item.action)
              : matchDescendants
          }
          className={props =>
            clsx(baseClassName, className?.(props), focusClassNames)
          }
          to={item.action}
          target={item.target}
          data-menu-item-id={item.id}
          ref={ref}
          {...linkProps}
        >
          {content}
        </NavLink>

        {/* Dropdown Menu (Only if action is `/pages/solutions`) */}
        {item.action === '/pages/solutions' && (
          <ul
            className={`absolute left-0 mt-2 z-30 w-fit p-16 !bg-white dark:!bg-black text-black shadow-lg border rounded-md transition-all duration-300 ${dropdownOpen ? 'opacity-100 visible' : 'opacity-0 invisible'
              }`}
          >
            <li className="mb-5">
              <NavLink
                to="/pages/solutions/team-collaboration"
                className="block px-8 py-6 rounded hover:bg-[#1159D4] hover:!text-white whitespace-nowrap"
              >
                Team Workspaces
              </NavLink>
            </li>
            <li className="mb-5">
              <NavLink
                to="/pages/solutions/secure-sharing"
                className="block px-8 py-6 rounded hover:bg-[#1159D4] hover:!text-white whitespace-nowrap"
              >
                Secure Sharing
              </NavLink>
            </li>
            <li className="mb-5">
              <NavLink
                to="/pages/solutions/secure-cloud-storage"
                className="block px-8 py-6 rounded hover:bg-[#1159D4] hover:!text-white whitespace-nowrap"
              >
                Secure Cloud Storage
              </NavLink>
            </li>
            <li className="mb-5">
              <NavLink
                to="/pages/solutions/work-remotely"
                className="block px-8 py-6 rounded hover:bg-[#1159D4] hover:!text-white whitespace-nowrap"
              >
                Secure Messaging
              </NavLink>
            </li>
          </ul>
        )}
      </div>
    );
  }
);
