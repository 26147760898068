import { FaInstagram, FaLinkedin, FaTwitter, FaYoutube } from "react-icons/fa";
import "../../../../../resources/client/css/landingPage.css"
import { Link } from "react-router-dom";
const NewFooter = () => {
  return (
 <>
    <footer className="footer-1 py-[60px] gradient-bg">
    <div className="container mx-auto py-4">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <div className="col-span-1 md:col-span-1 lg:col-span-1 mb-8 md:mb-0">
          <Link to="#" className="block mb-4">
            {/* <img src="storage/branding_media/c4eba1c8-37c5-4a75-ae75-da419d048f57.png" alt="logo" width="150" height="40" className="img-fluid" /> */}
            <img src="storage/branding_media/white-logo.svg" alt="logo" width="150" height="40" className="img-fluid" />
          
          </Link>
          <p className="text-gray-700 mt-20 w-[80%]">
            MyDocUploads is a file storage and document collaboration platform that helps teams stay safe, secure and connected in the cloud.
          </p>
          {/* <ul className="list-inline social-list-default social-hover-2 mt-20   flex">
            <li className="list-inline-item bg-[#fff] rounded-sm flex items-center justify-center">
              <a className="flex items-center justify-center" target="_blank" href="#">
                <FaTwitter className="fab fa-twitter text-[#0641AB]"/>
                </a>
            </li>
            <li className="list-inline-item bg-[#fff] rounded-sm flex items-center justify-center">
              <a className="flex items-center justify-center" target="_blank" href="#">
                <FaYoutube className="fab fa-youtube text-[#0641AB]" />
                </a>
            </li>
            <li className="list-inline-item bg-[#fff] rounded-sm flex items-center justify-center">
              <a className="flex items-center justify-center" target="_blank" href="#">
                <FaLinkedin className="fab fa-linkedin-in text-[#0641AB]" />
                </a>
            </li>
            <li className="list-inline-item bg-[#fff] rounded-sm flex items-center justify-center">
              <a className="flex items-center justify-center" target="_blank" href="#">
                <FaInstagram className="fab fa-instagram text-[#0641AB]"/>
                </a>
            </li>
          </ul> */}

<ul className="flex gap-4 mt-10">
      {[
        { icon: <FaTwitter />, href: "#" },
        { icon: <FaYoutube />, href: "#" },
        { icon: <FaLinkedin />, href: "#" },
        { icon: <FaInstagram />, href: "#" },
      ].map((item, index) => (
        <li key={index}>
          <a
            href={item.href}
            target="_blank"
            className="flex items-center justify-center p-6 bg-[#fff] rounded-sm shadow-md transition-transform duration-200 hover:scale-110"
          >
            <span className="text-[#0641AB] text-lg">{item.icon}</span>
          </a>
        </li>
      ))}
    </ul>
        </div>
        <div className="col-span-1 md:col-span-1 lg:col-span-1">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <h6 className="font-weight-normal font-semibold text-[#ffffff] text-lg mb-6">Resources</h6>
              <ul>
                {/* <li><Link to="/pages/help">Help</Link></li> */}
                <li><Link to="/pages/terms-of-service">Terms of Service</Link></li>
                <li><Link to="/pages/security-privacy">Security & Privacy</Link></li>
                <li><Link to="/pages/about-us">About Us</Link></li>
                {/* <li><Link to="/api-docs">API Documentation</Link></li> */}
              </ul>
            </div>
            <div>
              <h6 className="font-weight-normal font-semibold text-[#ffffff] text-lg mb-6">Products</h6>
              <ul>
                <li><Link to="/pricing">Pricing</Link></li>
                <li><a href="blog">Blog</a></li>
                <li><Link to="#">AI Studio</Link></li>
                <li><Link to="#">Enterprise</Link></li>
                <li><Link to="#">Performance</Link></li>
              </ul>
            </div>

          </div>
        </div>
        <div className="col-span-1 md:col-span-1 lg:col-span-1">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">

            <div>
              <h6 className="font-weight-normal font-semibold text-[#ffffff] text-lg mb-6">Company</h6>
              <ul>
                <li><Link to="/pages/about-us">Mission</Link></li>
                <li><Link to="#">Careers</Link></li>
                <li><Link to="/contact">Contact Us</Link></li>
                <li><Link to="#">Community</Link></li>
                <li><Link to="/pages/about-us">Our Team</Link></li>
              </ul>
            </div>
            <div>
              <h6 className="font-weight-normal font-semibold text-[#ffffff] text-lg mb-6">Support</h6>
              <ul>
                <li><Link to="/pages/faq">FAQ</Link></li>
                <li><Link to="login">My Account</Link></li>
                <li><Link to="register">Register</Link></li>
                <li><Link to="/pages/solutions">Solutions</Link></li>
                <li><Link to="/pages/features">Features</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>


  <div className="flex items-center justify-center h-[3rem]">
    <p className="text-sm">Copyright © 2024 MyDocUploads, All Rights Reserved</p>
  </div>
 </>
  )
}

export default NewFooter
