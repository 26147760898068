import { Navbar } from '@common/ui/navigation/navbar/navbar'
import NewFooter from '@common/ui/new-footer/NewFooter'
import AddBar from '../AddBar'
import { Link } from 'react-router-dom'

const Blog = () => {
    return (
        <>
            <div className="container mx-auto">
                <Navbar
                    color="transparent"
                    className="flex-shrink-0"
                    menuPosition="homepage-navbar"
                />
            </div>
            <AddBar />
            <BlogPage />
            <NewFooter />
        </>
    )
}

export default Blog

function BlogPage() {
    const cardData = Array(9).fill({
        imgSrc: "./public/images/about-us/effortless.svg",
        title: "Noteworthy technology acquisitions 2021",
        description: "Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.",
        linkText: "Read more"
    });
    return (
        <>
            <div className='grid grid-1'>
                <div className='py-90 flex justify-center items-center' style={{ background: "radial-gradient(51.81% 142.14% at 50% 50%, #1869F1 0%, #0948B3 100%)" }}>
                    <h1 className='text-3xl md:text-6xl font-bold text-center align-middle text-white lg:w-10/12 xl:w-1/2 '>Explore, Learn, and Stay Ahead
                        with our Blogs</h1>
                </div>
            </div>
            <div className="container mx-auto">
                <div className="grid grid-col-1 mb-32 mt-56">
                    <h1 className='text-3xl md:text-6xl font-bold text-[#383838]'>Latest <span className='text-[#3B82F6]'>Articles</span></h1>
                </div>
                <div className="grid grid-cols-12 gap-16">
                    <div className="col-span-12 md:col-span-8">
                        <div className="flex flex-col items-center bg-white border border-gray-200 rounded-2xl shadow-sm lg:flex-row  hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
                            <img className="object-cover w-full rounded-t-2xl md:max-h-256   lg:rounded-s-2xl"
                                src="./public/images/about-us/effortless.svg" alt="" />
                            <div className="flex flex-col justify-between p-10 leading-normal ms-10">
                                <h5 className="mb-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-white">Noteworthy technology acquisitions 2021</h5>
                                <p className="mb-3 text-sm font-normal text-[#475569] dark:text-gray-400">Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-12 md:col-span-4">
                        <div className='flex flex-col gap-16'>
                            <div className="flex flex-col items-center bg-white border border-gray-200 rounded-2xl shadow-sm lg:flex-row  hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
                                <img className="object-cover w-full rounded-t-xl md:max-h-120 lg:rounded-s-2xl"
                                    src="./public/images/about-us/effortless.svg" alt="" />
                                <div className="flex flex-col justify-between p-6 leading-normal ms-10">
                                    <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">Access free 10GB of secure cloud storage</h5>
                                </div>
                            </div>
                            <div className="flex flex-col items-center bg-white border border-gray-200 rounded-2xl shadow-sm lg:flex-row  hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
                                <img className="object-cover w-full rounded-t-xl md:max-h-120 lg:rounded-s-2xl"
                                    src="./public/images/about-us/effortless.svg" alt="" />
                                <div className="flex flex-col justify-between p-6 leading-normal ms-10">
                                    <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">Access free 10GB of secure cloud storage</h5>
                                </div>
                            </div>

                        </div>
                    </div>
                    {cardData.map((card, index) => (
                        <Link to="/pages/blog-detail" key={index} className="col-span-12 md:col-span-4">
                            <div className=" bg-white border border-gray-200 rounded-2xl shadow-sm dark:bg-gray-800 dark:border-gray-700">
                                <img className="rounded-t-xl" src={card.imgSrc} alt={card.title} />
                                <div className="p-16">
                                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                                        {card.title}
                                    </h5>
                                    <p className="mb-3 text-sm font-normal text-[#475569] dark:text-gray-400">
                                        {card.description}
                                    </p>
                                    <div className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                        {card.linkText}
                                        <svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </>
    );
}