import {Navbar} from '@common/ui/navigation/navbar/navbar';
import NewFooter from '@common/ui/new-footer/NewFooter';
import React, {useState} from 'react';

const FAQSection = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggleFaq = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    {
      question: 'What features does MyDocUploads offer?',
      answer:
        'MyDocUploads provides secure storage, collaboration tools, and easy document management.',
    },
    {
      question: 'How can MyDocUploads enhance document management?',
      answer:
        'It helps streamline workflows, improve accessibility, and ensure data security.',
    },
    {
      question: 'Is MyDocUploads secure?',
      answer:
        'Yes, it uses end-to-end encryption and multi-layer security measures.',
    },
    {
      question: 'Can I collaborate with my team using MyDocUploads?',
      answer: 'Yes, you can share documents and work together in real time.',
    },
    {
      question: 'What are the pricing plans for MyDocUploads?',
      answer:
        'We offer various plans tailored to different needs. Visit our pricing page for details.',
    },
  ];

  const toggleFAQ = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <>
      <h1 className="mb-50 mt-30 text-center text-4xl font-semibold">
        Frequently Asked Questions
      </h1>

      <div className="mx-auto my-10 grid max-w-7xl grid-cols-1 gap-8 md:grid-cols-2">
        <div className="flex flex-col gap-8">
          {faqs.slice(0, 3).map((faq, index) => (
            <div
              key={index}
              className="border-gray-300 rounded-lg border bg-white p-14 shadow-sm"
            >
              <button
                className="text-gray-700 flex w-full items-center justify-between p-4 text-left text-lg font-medium hover:text-primary"
                onClick={() => toggleFAQ(index)}
              >
                {index + 1}. {faq.question}
                <svg
                  className={`h-20 w-20 transform transition-transform duration-300 ${activeIndex === index ? 'rotate-180' : ''}`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="#3A86FF"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
              {activeIndex === index && (
                <div className="text-gray-600 border-gray-200 border-t p-14">
                  {faq.answer}
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="flex flex-col gap-8">
          {faqs.slice(3).map((faq, index) => (
            <div
              key={index + 3}
              className="border-gray-300 rounded-lg border bg-white p-14 shadow-sm"
            >
              <button
                className="text-gray-700 flex w-full items-center justify-between p-4 text-left text-lg font-medium hover:text-primary"
                onClick={() => toggleFAQ(index + 3)}
              >
                {index + 4}. {faq.question}
                <svg
                  className={`h-20 w-20 transform transition-transform duration-300 ${activeIndex === index + 3 ? 'rotate-180' : ''}`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="#3A86FF"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
              {activeIndex === index + 3 && (
                <div className="text-gray-600 border-gray-200 border-t p-14">
                  {faq.answer}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

const Faq = () => {
  return (
    <>
      <Navbar
        color="transparent"
        className="flex-shrink-0"
        menuPosition="homepage-navbar"
      />
      <FAQSection />
      <NewFooter />
    </>
  );
};

export default Faq;
export {FAQSection};

// import { Navbar } from '@common/ui/navigation/navbar/navbar';
// import NewFooter from '@common/ui/new-footer/NewFooter';
// import React, { useState } from 'react';

// const Faq = () => {
//   const [activeIndex, setActiveIndex] = useState(null);

//   const toggleFaq = (index: any) => {
//     setActiveIndex(activeIndex === index ? null : index);
//   };

//   const faqs = [
//     {
//       question: 'What payment methods do you accept?',
//       answer: 'We accept all major credit cards and PayPal.'
//     },
//     {
//       question: 'How can I track my order?',
//       answer: 'You can track your order using our website.'
//     },
//     {
//       question: 'Do you offer refunds?',
//       answer: 'Yes, we offer refunds within 30 days of purchase.'
//     },
//     // Add more FAQs as needed
//   ];

//   return (
//     <>
//       <Navbar
//         color="transparent"
//         className="flex-shrink-0"
//         menuPosition="homepage-navbar"
//       />
//       <div className="container mx-auto p-4 my-50 mb-[100px]">
//         <div className="flex justify-center">
//           <div className="w-full md:w-1/2">
//             <h1 className="text-3xl text-center font-semibold mb-50">Frequently Asked Questions</h1>
//             <div className="space-y-4">
//               {faqs.map((faq, index) => (
//                 <div key={index} className="border border-gray-300 rounded-md px-5">
//                   <div
//                     className="flex justify-between items-center p-4 cursor-pointer"
//                     onClick={() => toggleFaq(index)}
//                   >
//                     <h2 className="text-xl font-semibold text-[#363636]">{faq.question}</h2>
//                     <svg
//                       className={`w-20 h-20 transform transition-transform duration-300 ${activeIndex === index ? 'rotate-180' : ''
//                         }`}
//                       fill="none"
//                       viewBox="0 0 24 24"
//                       stroke="currentColor"
//                     >
//                       <path
//                         strokeLinecap="round"
//                         strokeLinejoin="round"
//                         strokeWidth="2"
//                         d="M19 9l-7 7-7-7"
//                       />
//                     </svg>
//                   </div>
//                   {activeIndex === index && (
//                     <div className="p-4 border-t border-gray-300">
//                       <p className="text-gray-700 text-sm text-[#475569]">{faq.answer}</p>
//                     </div>
//                   )}
//                 </div>
//               ))}
//             </div>
//           </div>
//         </div>
//       </div>
//       <NewFooter />
//     </>
//   );
// };

// export default Faq;
