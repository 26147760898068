import {Footer} from '@common/ui/footer/footer';
import {Navbar} from '@common/ui/navigation/navbar/navbar';
import NewFooter from '@common/ui/new-footer/NewFooter';
import {IoCheckmarkCircleOutline} from 'react-icons/io5';
import {Link} from 'react-router-dom';
import {Tabs, TabList, Tab, TabPanel} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import AddBar from './AddBar';
const Features = () => {
  return (
    <>
      <div className="container mx-auto">

        <Navbar
          color="transparent"
          className="flex-shrink-0"
          menuPosition="homepage-navbar"
        />
      </div>
<AddBar/>
<div className="container mx-auto">

        <FeaturesTabs />
      </div>
      <NewFooter />
    </>
  );
};

export default Features;

function FeaturesTabs() {
  return (
    <section className="promo-section lg:pt-[50px] ">
      <FeaturesHeroSection />
      <PowerfulFeatures />
      <SecureFileStorage />
      <GetStarted />






      
      {/* <div className="container mx-auto">
        <div className="flex flex-col">
          <h1 className="fw-[700] text-center  md:text-[40px] lg:text-7xl">
            Discover What You Can Do with Our Platform's{' '}
            <span className="text-[#014CDA]">Powerful Features</span>
          </h1>
        </div>
      </div>

      <Tabs className="features-tabs mt-20">
        <TabList className="flex flex-col items-center justify-center md:flex-row md:items-center">
          <Tab>Secure messaging</Tab>
          <Tab>Anywhere Access</Tab>
          <Tab>Secure Collaboration</Tab>
          <Tab>Professional File Transfers</Tab>
          <Tab>Security & Compliance</Tab>
        </TabList>

        <TabPanel>
          <SecureMessaging />
        </TabPanel>
        <TabPanel>
          <AnywhereAccess />
        </TabPanel>
        <TabPanel>
          <SecureCollaboration />
        </TabPanel>
        <TabPanel>
          <ProfessionalFileTransfers />
        </TabPanel>
        <TabPanel>
          <SecurityCompliance />
        </TabPanel>
      </Tabs> */}
    </section>
  );
}

function FeaturesHeroSection() {
  return (
    <>
      <div className="grid-cols-1/2 item-center grid justify-center gap-5">
        <h1 className="text-center text-[2rem] font-bold leading-[3rem] lg:text-[4rem]">
          Work Smarter with{' '}
          <span className="text-[#014CDA]">MyDocsUploads</span>
        </h1>
        <p className="mt-10 px-[15%] text-center">
          Your all-in-one solution for encrypted file storage, seamless
          collaboration and effortless file sharing. Whether you're working
          remotely or managing a team, our platform ensures your data stays
          protected, accessible, and organized.
        </p>

        <div className="flex items-center justify-center gap-6 mt-20">
          <button className="btn-orange-colored !px-[30px]"
          
          >Get Started </button>
          <button className="btn-white border border-[#f67a3c]">Compare Plans </button>
        </div>
        <div className="relative flex flex-col items-center mt-30 md:mt-0">
          <div className="boxshadow-lg  -left-50 top-60 bg-white dark:bg-black md:absolute md:m-20">
            <div className="card mb-20 rounded-[15px] p-20 md:w-[25rem] dark:bg-black">
              <div className="flex items-start gap-10">
                <img
                  src="./public/images/features/Secure-Storage-Icon.svg"
                  className="w-[10%]"
                />
                <div>
                  <h4 className="font-semibold">
                    Secure File Storage & Anywhere Access
                  </h4>
                  <p className="mt-2 text-sm lg:text-[#475569] dark:text-white">
                    Securely store your files in the cloud and access them
                    anytime with MyDocUploads.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <img
            src="./public/images/features/hero-section-image.svg"
            width="auto"
            alt=""
          />
          <div className="-right-50 bottom-60 m-20 bg-white dark:bg-black md:absolute">
            <div className="card mb-20 rounded-[15px] p-20 md:w-[25rem] dark:bg-black">
              <div className="flex items-start gap-10">
                <img
                  src="./public/images/features/Share-Collaborate-Icon.svg"
                  className="w-[10%]"
                />
                <div>
                  <h4 className="font-semibold">
                    Share & Collaborate with Ease
                  </h4>
                  <p className="mt-2 text-sm lg:text-[#475569] dark:text-white">
                    Share files and collaborate easily. Set permissions, enable
                    password protection, and track activity.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function PowerfulFeatures() {
  return (
    <>
      <div className="grid-cols-1/2 item-center grid justify-center gap-5">
        <h1 className="text-center text-[2rem] font-bold leading-[3rem] lg:text-[2.5rem]">
          Powerful Features to Keep Your Files
          <p className="text-[#014CDA]">Secure & Accessible</p>
        </h1>
        <p className="mt-5 text-center">
          Store, share, and collaborate on files with top security and easy
          access.
        </p>

        <div className="grid grid-cols-1 gap-16 md:grid-cols-2 lg:grid-cols-3">
          {[
            {
              img: './public/images/features/feature1.svg',
              title: 'Access your files from anywhere, any time',
              text: 'Access your files instantly from all your computers, mobile devices and the web. Work from home, from the office or from the most idea-inspiring places on the planet.',
            },
            {
              img: './public/images/features/feature2.svg',
              title: 'Access your files from anywhere, any time',
              text: 'Access your files instantly from all your computers, mobile devices and the web. Work from home, from the office or from the most idea-inspiring places on the planet.',
            },
            {
              img: './public/images/features/feature3.svg',
              title: 'Access your files from anywhere, any time',
              text: 'Access your files instantly from all your computers, mobile devices and the web. Work from home, from the office or from the most idea-inspiring places on the planet.',
            },
          ].map((feature, index) => (
            <div key={index} className="rounded-lg bg-[#F7FAFF] dark:bg-transparent dark:border p-24">
              <img
                src={feature.img}
                alt={feature.title}
                className="mb-4 w-[20%]"
              />
              <h1 className="text-lg font-semibold lg:text-2xl">
                {feature.title}
              </h1>
              <p className="text-gray-600">{feature.text}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
function SecureFileStorage() {
  return (
    <>
      <div className="border-b pb-40">
        <div className="grid gap-5 pt-50 md:grid-cols-2">
          <div className="item-center flex flex-col justify-center lg:px-50">
            <div>
              <img
                src="./public/images/features/starsImage.svg"
                className="w-auto"
              />
            </div>{' '}
            <h1 className="font-bold md:leading-[48px] lg:text-[2.5rem]">
              <span className="text-primary"> Secure </span> File Storage &
              <span className="text-primary"> Anywhere Access</span>{' '}
            </h1>
            <ul className="mt-4 space-y-4">
              <li className="text-md flex items-center gap-2">
                <IoCheckmarkCircleOutline className="text-xl text-primary" />{' '}
                Store and access your files securely from anywhere
              </li>
              <li className="text-md flex items-center gap-2">
                <IoCheckmarkCircleOutline className="text-xl text-primary" />
                Works on all devices—desktop, mobile, and tablets
              </li>
              <li className="text-md flex items-center gap-2">
                <IoCheckmarkCircleOutline className="text-xl text-primary" />
                Keep your data safe with advanced cloud security
              </li>
              <li className="text-md flex items-center gap-2">
                <IoCheckmarkCircleOutline className="text-xl text-primary" />
                Ensures accessibility even when working remotely
              </li>
            </ul>
          </div>
          <div>
            <img
              src="./public/images/solutions/HeroSideImage.svg"
              width="100%"
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="border-b pb-40">
        <div className="grid gap-5 pt-50 md:grid-cols-2">
          <div>
            <img
              src="./public/images/features/shareCollaborateSideImage.svg"
              width="100%"
              alt="isamaaaaaaaa"
            />
          </div>

          <div className="item-center flex flex-col justify-center lg:px-50">
            <div>
              <img
                src="./public/images/features/folderStar.svg"
                className="w-auto"
              />
            </div>{' '}
            <h1 className="font-bold md:leading-[48px] lg:text-[2.5rem]">
              <span className="text-primary"> Share & Collaborate </span> with
              Ease
            </h1>
            <ul className="mt-4 space-y-4">
              <li className="text-md flex items-center gap-2">
                <IoCheckmarkCircleOutline className="text-xl text-primary" />{' '}
                Easily share files with teammates or clients
              </li>
              <li className="text-md flex items-center gap-2">
                <IoCheckmarkCircleOutline className="text-xl text-primary" />
                Set permissions for view, edit, or download access
              </li>
              <li className="text-md flex items-center gap-2">
                <IoCheckmarkCircleOutline className="text-xl text-primary" />
                Collaborate securely with built-in team tools
              </li>
              <li className="text-md flex items-center gap-2">
                <IoCheckmarkCircleOutline className="text-xl text-primary" />
                Maintain productivity with seamless file-sharing options
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="border-b pb-40">
        <div className="grid gap-5 pt-50 md:grid-cols-2">
          <div className="item-center flex flex-col justify-center lg:px-50">
            <div>
              <img
                src="./public/images/features/handStar.svg"
                className="w-auto"
              />
            </div>{' '}
            <h1 className="font-bold md:leading-[48px] lg:text-[2.5rem]">
              End-to-End Encryption
              <span className="text-primary"> for Ultimate Security </span>
            </h1>
            <ul className="mt-4 space-y-4">
              <li className="text-md flex items-center gap-2">
                <IoCheckmarkCircleOutline className="text-xl text-primary" />{' '}
                Protects your data with industry-leading encryption
              </li>
              <li className="text-md flex items-center gap-2">
                <IoCheckmarkCircleOutline className="text-xl text-primary" />
                Ensures only authorized users can access your files
              </li>
              <li className="text-md flex items-center gap-2">
                <IoCheckmarkCircleOutline className="text-xl text-primary" />
                Secure file transfers with advanced privacy controls
              </li>
              <li className="text-md flex items-center gap-2">
                <IoCheckmarkCircleOutline className="text-xl text-primary" />
                Prevents unauthorized access and cyber threats
              </li>
            </ul>
          </div>
          <div>
            <img
              src="./public/images/features/endEncryption.svg"
              width="100%"
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  );
}

function GetStarted() {
  return (
    <>
    <div className="my-50 flex flex-col md:flex-row justify-between items-center px-30 rounded-2xl bg-gradient-to-r from-[#34A4FF] to-primary py-10 text-center text-white dark:bg-gradient-to-r from-[#0244C2] to-[#34A4FF]">
   
      <img src="./public/images/features/unlock-folder.svg" alt="Folder Icon" className="w-100 md:w-auto" />
      
    
      <div className="flex flex-col items-center text-center mx-6 space-y-3 flex-1">
        <h2 className="text-xl lg:text-4xl font-bold">Start Storing & Sharing Securely</h2>
        <p className="text-xs lg:text-sm">
          Experience seamless, encrypted file storage and collaboration. Sign up now and take control of your data.
        </p>
        <button className="btn-white">
          Get Started for Free
        </button>
        <p className="text-xs opacity-70">No credit card required*</p>
      </div>
      
    
      <img src="./public/images/features/cloud-upload.svg" alt="Cloud Upload Icon" className="w-120 md:w-auto" />
    </div>

    </>
  );
}

function SecureMessaging() {
  return (
    <>
      <div className="grid gap-5 pt-50 md:grid-cols-2">
        <div className="item-center flex flex-col justify-center lg:px-50">
          <h1 className="text-bold md:leading-[48px] lg:text-[40px]">
            Secure Message Service - Send{' '}
            <span className="text-[#014CDA]">Encrypted Messages & Files</span>{' '}
            with Confidence
          </h1>
          <p className="mt-10 text-sm lg:text-[#475569]">
            In today's digital age, security and privacy are more critical than
            ever. At MyDocUploads, we understand the need to protect sensitive
            information, which is why we've developed our Secure Message
            service. This service allows you to send encrypted messages and file
            attachments to anyone, ensuring your data is safe and confidential.
          </p>
        </div>
        <div>
          <img
            src="./public/images/features/secure-messaging.svg"
            width="100%"
            alt=""
          />
        </div>
      </div>

      <div className="item-center grid justify-center gap-5 md:grid-cols-2 lg:pt-50">
        <div>
          <img
            src="./public/images/features/feature-1.svg"
            width="100%"
            className="mt-20 md:mt-0"
            alt=""
          />
        </div>
        <div className="item-center flex flex-col justify-center lg:px-50">
          <p className="text-sm text-[#F67A3C]">End-to-End Encryption</p>
          <h1 className="text-bold md:leading-[48px] lg:text-[40px]">
            Why Choose Our{' '}
            <span className="text-[#014CDA]">Secure Message</span> Service?
          </h1>
          <p className="mt-10 text-sm lg:text-[#475569]">
            Our Secure Message service uses advanced encryption technology to
            protect your messages and file attachments. This ensures that only
            the intended recipient can access the information, keeping your data
            safe from prying eyes.
          </p>
        </div>
      </div>

      <div className="item-center grid justify-center gap-5 pt-50 md:grid-cols-2">
        <div className="item-center flex flex-col justify-center lg:px-50">
          <p className="text-sm text-[#F67A3C]">Simple and User-Friendly</p>
          <h1 className="text-bold leading-[48px] lg:text-[40px]">
            How It <span className="text-[#014CDA]">Works</span>?
          </h1>
          <p className="text-sm md:mt-10 lg:text-[#475569]">
            Sending a secure message with MyDocUploads is straightforward.
            Simply compose your message, attach any necessary files, and send it
            to your recipient. Our platform handles the encryption process
            automatically.
          </p>
        </div>
        <div>
          <img
            src="./public/images/features/how-work.svg"
            width="100%"
            className="mt-10"
            alt=""
          />
        </div>
      </div>

      <div className="grid-cols-1/2 item-center mt-50 grid justify-center gap-5 md:pt-50">
        <h1 className="text-bold text-center text-[30px] leading-[48px] lg:text-[40px]">
          Key <span className="text-[#014CDA]">Features</span> of Secure Message
        </h1>
        <div className="relative md:mt-50">
          <div className="top-0 bg-white md:absolute md:m-20">
            <div className="card mb-20 rounded-[15px] p-10 md:w-[25rem]">
              <h4 className="text-[#014CDA]">Automatic Data Purge</h4>
              <p className="mt-2 text-sm lg:text-[#475569]">
                Sending a secure message with MyDocUploads is straightforward.
                Simply compose your message, attach any necessary files, and
                send it to your recipient. Our platform handles the encryption
                process automatically.
              </p>
            </div>
          </div>
          <img
            src="./public/images/features/message-feature.svg"
            width="100%"
            alt=""
          />
          <div className="bottom-0 right-0 m-20 bg-white md:absolute">
            <div className="card rounded-[15px] p-10 md:w-[25rem]">
              <h4 className="text-[#014CDA]">Link Expiry Options</h4>
              <p className="mt-2 text-sm lg:text-[#475569]">
                Customize how long your link stays active. Choose from various
                expiry options to fit your needs, providing flexibility and
                added security.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="md:grid-cols-1/2 item-center grid justify-center pt-50 md:m-50">
        <h1 className="text-bold text-center text-[30px] leading-[48px] lg:text-[40px]">
          <span className="text-[#014CDA]">Benefits</span> of Using Secure
          Message
        </h1>
        <div className="item-center grid justify-center gap-30 md:grid-cols-2 md:pt-50">
          <div className="card p-20">
            <h4 className="text-bold text-[30px] text-[#383838]">
              Peace of Mind
            </h4>
            <p className="mt-10 text-sm lg:text-[#475569]">
              With our Secure Message service, you can rest assured that your
              sensitive information is protected. Whether you're sharing
              personal details, business documents, or confidential
              communications, our service keeps your data secure.
            </p>
            <img
              src="./public/images/features/peace-mind.svg"
              width="100%"
              alt=""
            />
          </div>
          <div className="card p-20">
            <h4 className="text-bold text-[30px] text-[#383838]">
              Convenience
            </h4>
            <p className="mt-10 text-sm lg:text-[#475569]">
              No need for complex setup or technical knowledge. Our platform is
              designed to be user-friendly, making it easy for anyone to send
              encrypted messages and files.
            </p>
            <img
              src="./public/images/features/convience.svg"
              width="100%"
              alt=""
            />
          </div>
        </div>

        <div className="card mt-100 p-20">
          <h1 className="text-bold text-center text-[30px] leading-[48px] lg:text-[40px]">
            <span className="text-[#014CDA]">Get Started</span> Today!
          </h1>
          <p className="mt-10 text-center text-sm lg:text-[#475569]">
            Protect your communications with our Secure Message service. Sign up
            now to start sending encrypted messages and file attachments with
            confidence. Experience the peace of mind that comes with knowing
            your information is{' '}
            <span className="font-[700] italic">secure</span> and{' '}
            <span className="font-[700] italic">private</span>.
          </p>
          <p className="mt-10 text-center text-sm lg:text-[#475569]">
            Sign up for a{' '}
            <span className="font-[700] italic">FREE Basic Account</span> and
            start using Secure Message right away!
          </p>
          <Link to="/register" className="m-auto">
            <button className="btn-login mt-20  w-[150px]">Sign Up Now</button>
          </Link>
        </div>
      </div>
    </>
  );
}

function AnywhereAccess() {
  return (
    <>
      <div className="grid gap-5 pt-50 md:grid-cols-2">
        <div className="item-center flex flex-col justify-center lg:px-50">
          <p className="text-sm text-[#F67A3C]">
            Your Documents, Available Whenever and Wherever You Need Them
          </p>
          <h1 className="text-bold md:leading-[48px] lg:text-[40px]">
            Access Your Files{' '}
            <span className="text-[#014CDA]">Anywhere, Anytime</span>
          </h1>
          <p className="mt-10 text-sm lg:text-[#475569]">
            With MyDocUploads, your important documents are always at your
            fingertips. Whether you're at the office, at home, or on the go, our
            platform ensures you can access your files from any device, 24/7.
            Say goodbye to the hassle of physical storage and enjoy the
            convenience of cloud-based document management.
          </p>
        </div>
        <div>
          <img
            src="./public/images/features/anywhere-access.svg"
            width="100%"
            alt=""
          />
        </div>
      </div>

      <div className="item-center grid justify-center gap-5 md:grid-cols-2 lg:pt-50">
        <div>
          <img
            src="./public/images/features/key-features.svg"
            width="100%"
            className="mt-20 md:mt-0"
            alt=""
          />
        </div>
        <div className="item-center flex flex-col justify-center lg:px-50">
          <h1 className="text-bold md:leading-[48px] lg:text-[40px]">
            Benefits of{' '}
            <span className="text-[#014CDA]">Anywhere, Anytime Access</span>
          </h1>
          <ul className="mt-5">
            <li className="mt-10">
              <span className="font-bold text-[#F67A3C]">Flexibility:</span>{' '}
              Work from any location without being tied to a single device or
              physical files.
            </li>
            <li className="mt-10">
              <span className="font-bold text-[#F67A3C]">Productivity:</span>{' '}
              Quickly find and retrieve the documents you need, reducing
              downtime and increasing efficiency.
            </li>
            <li className="mt-10">
              <span className="font-bold text-[#F67A3C]">Backup:</span> Your
              files are stored securely in the cloud, providing automatic backup
              and easy recovery in case of device loss or failure.
            </li>
          </ul>
        </div>
      </div>

      <div className="item-center grid justify-center gap-5 pt-50 md:grid-cols-2">
        <div className="item-center flex flex-col justify-center lg:px-50">
          <h1 className="text-bold md:leading-[48px] lg:text-[40px]">
            How <span className="text-[#014CDA]"> It Works?</span>
          </h1>
          <p className="mt-10 text-sm lg:text-[#475569]">
            MyDocUploads uses cutting-edge cloud technology to store your
            documents securely online. Simply upload your files to our platform,
            and access them using our web interface or mobile app. Whether you
            need to review a contract, share a report, or work on a project,
            MyDocUploads makes it easy to stay connected with your documents.
          </p>
        </div>
        <div>
          <img
            src="./public/images/features/how-work.svg"
            width="100%"
            className="mt-10"
            alt=""
          />
        </div>
      </div>
    </>
  );
}

function SecureCollaboration() {
  return (
    <>
      <div className="grid gap-5 pt-50 md:grid-cols-2">
        <div className="item-center flex flex-col justify-center lg:px-50">
          <p className="text-sm text-[#F67A3C]">
            Seamless Collaboration and Secure File Sharing
          </p>
          <h1 className="text-bold md:leading-[48px] lg:text-[40px]">
            <span className="text-[#014CDA]">
              Share and Collaborate Securely{' '}
            </span>{' '}
            with Anyone
          </h1>
          <p className="mt-10 text-sm lg:text-[#475569]">
            Enhance your team's productivity with MyDocUploads. Share files
            securely with colleagues, clients, and partners. Our platform
            supports real-time collaboration, making it easy to work together,
            provide feedback, and achieve your goals faster. Experience the ease
            of secure file sharing without compromising on data protection.
          </p>
        </div>
        <div>
          <img
            src="./public/images/features/secure-collaboration.svg"
            width="100%"
            alt=""
          />
        </div>
      </div>

      <div className="item-center grid justify-center gap-5 md:grid-cols-2 lg:pt-50">
        <div>
          <img
            src="./public/images/features/key-features.svg"
            width="100%"
            className="mt-20 md:mt-0"
            alt=""
          />
        </div>
        <div className="item-center flex flex-col justify-center lg:px-50">
          <h1 className="text-bold md:leading-[48px] lg:text-[40px]">
            <span className="text-[#014CDA]">Key Features </span> of Secure
            Collaboration
          </h1>
          <ul className="mt-5">
            <li className="mt-10">
              <span className="font-bold text-[#F67A3C]">
                Real-Time Editing:
              </span>{' '}
              Collaborate on documents in real-time, ensuring everyone is on the
              same page.
            </li>
            <li className="mt-10">
              <span className="font-bold text-[#F67A3C]">Version Control:</span>{' '}
              Track changes and access previous versions to avoid conflicts and
              maintain document integrity.
            </li>
            <li className="mt-10">
              <span className="font-bold text-[#F67A3C]">Access Controls:</span>{' '}
              Set permissions and control who can view, edit, or share your
              files to ensure data security.
            </li>
          </ul>
        </div>
      </div>

      <div className="item-center grid justify-center gap-5 pt-50 md:grid-cols-2">
        <div className="item-center flex flex-col justify-center lg:px-50">
          <h1 className="text-bold md:leading-[48px] lg:text-[40px]">
            Benefits of{' '}
            <span className="text-[#014CDA]"> Collaboration Made Easy</span>
          </h1>
          <p className="mt-10 text-sm lg:text-[#475569]">
            MyDocUploads integrates seamlessly with popular productivity tools
            like Microsoft Office and Google Workspace, allowing you to edit
            documents directly from our platform. Share files via secure links
            or invite collaborators via email. Our intuitive interface ensures
            everyone can collaborate effectively, regardless of their technical
            expertise.
          </p>
        </div>
        <div>
          <img
            src="./public/images/features/benefits.svg"
            width="100%"
            className="mt-10"
            alt=""
          />
        </div>
      </div>
    </>
  );
}

function ProfessionalFileTransfers() {
  return (
    <>
      <div className="grid gap-5 pt-50 md:grid-cols-2">
        <div className="item-center flex flex-col justify-center lg:px-50">
          <p className="text-sm text-[#F67A3C]">
            Professional File Transfers Made Simple
          </p>
          <h1 className="text-bold md:leading-[48px] lg:text-[40px]">
            Send and Receive Files
            <span className="text-[#014CDA]">Professionally</span>
          </h1>
          <p className="mt-10 text-sm lg:text-[#475569]">
            Elevate your business communication with MyDocUploads. Send and
            receive large files with confidence, knowing your data is secure and
            the process is streamlined. Our professional-grade file transfer
            service ensures your documents reach their destination safely and
            efficiently, reflecting the professionalism of your brand.
          </p>
        </div>
        <div>
          <img
            src="./public/images/features/professional-file-transfers.svg"
            width="100%"
            alt=""
          />
        </div>
      </div>

      <div className="item-center grid justify-center gap-5 md:grid-cols-2 lg:pt-50">
        <div>
          <img
            src="./public/images/features/key-features.svg"
            width="100%"
            className="mt-20 md:mt-0"
            alt=""
          />
        </div>
        <div className="item-center flex flex-col justify-center lg:px-50">
          <h1 className="text-bold md:leading-[48px] lg:text-[40px]">
            <span className="text-[#014CDA]">Benefits </span> of Professional
            File Transfers
          </h1>
          <ul className="mt-5">
            <li className="mt-10">
              <span className="font-bold text-[#F67A3C]">
                Large File Support:{' '}
              </span>{' '}
              Easily send and receive files of any size, avoiding email
              attachment limits.
            </li>
            <li className="mt-10">
              <span className="font-bold text-[#F67A3C]">
                Secure Transmission:{' '}
              </span>{' '}
              Our platform uses encryption to protect your files during
              transfer, ensuring they remain confidential.
            </li>
            <li className="mt-10">
              <span className="font-bold text-[#F67A3C]">
                Tracking and Notifications:{' '}
              </span>{' '}
              Receive notifications when files are delivered and accessed,
              giving you complete visibility and control.
            </li>
          </ul>
        </div>
      </div>

      <div className="item-center grid justify-center gap-5 pt-50 md:grid-cols-2">
        <div className="item-center flex flex-col justify-center lg:px-50">
          <h1 className="text-bold md:leading-[48px] lg:text-[40px]">
            How <span className="text-[#014CDA]">It Works?</span>
          </h1>
          <p className="mt-10 text-sm lg:text-[#475569]">
            Sending files with MyDocUploads is simple and secure. Upload your
            file, enter the recipient's email address, and click send. The
            recipient will receive a secure link to download the file. For added
            security, you can set expiration dates and password-protect your
            links. Whether you're sending a large presentation or a confidential
            report, MyDocUploads ensures your files are delivered safely and
            professionally.
          </p>
        </div>
        <div>
          <img
            src="./public/images/features/how-work.svg"
            width="100%"
            className="mt-10"
            alt=""
          />
        </div>
      </div>
    </>
  );
}

function SecurityCompliance() {
  return (
    <>
      <div className="grid gap-5 pt-50 md:grid-cols-2">
        <div className="item-center flex flex-col justify-center lg:px-50">
          <p className="text-sm text-[#F67A3C]">
            Advanced Security Features to Protect Your Data
          </p>
          <h1 className="text-bold md:leading-[48px] lg:text-[40px]">
            Security & Compliance Built for
            <span className="text-[#014CDA]">Peace of Mind</span>
          </h1>
          <p className="mt-10 text-sm lg:text-[#475569]">
            At MyDocUploads, we prioritize your data's security and compliance.
            Our platform is designed with state-of-the-art encryption, regular
            security audits, and strict compliance with industry standards. Rest
            easy knowing your sensitive information is protected against
            unauthorized access and breaches.
          </p>
        </div>
        <div>
          <img
            src="./public/images/features/security-compliance.svg"
            width="100%"
            alt=""
          />
        </div>
      </div>

      <div className="item-center grid justify-center gap-5 md:grid-cols-2 lg:pt-50">
        <div>
          <img
            src="./public/images/features/key-features.svg"
            width="100%"
            className="mt-20 md:mt-0"
            alt=""
          />
        </div>
        <div className="item-center flex flex-col justify-center lg:px-50">
          <h1 className="text-bold md:leading-[48px] lg:text-[40px]">
            Comprehensive{' '}
            <span className="text-[#014CDA]">Security Measures </span>
          </h1>
          <ul className="mt-5">
            <li className="mt-10">
              <span className="font-bold text-[#F67A3C]">Encryption: </span> All
              data is encrypted both in transit and at rest, ensuring it remains
              secure.
            </li>
            <li className="mt-10">
              <span className="font-bold text-[#F67A3C]">
                Access Controls:{' '}
              </span>{' '}
              Manage user permissions to control who can access and edit your
              documents.
            </li>
            <li className="mt-10">
              <span className="font-bold text-[#F67A3C]">Audit Trails: </span>{' '}
              Maintain detailed logs of all file access and activity for
              compliance and security purposes.
            </li>
          </ul>
        </div>
      </div>

      <div className="item-center grid justify-center gap-5 pt-50 md:grid-cols-2">
        <div className="item-center flex flex-col justify-center lg:px-50">
          <h1 className="text-bold md:leading-[48px] lg:text-[40px]">
            Compliance You <span className="text-[#014CDA]">Can Trust</span>
          </h1>
          <p className="mt-10 text-sm lg:text-[#475569]">
            MyDocUploads complies with industry-leading standards and
            regulations, including GDPR, HIPAA, and SOC 2. Our commitment to
            compliance ensures that your data is handled with the utmost care
            and in accordance with all relevant laws. Trust MyDocUploads to
            provide a secure environment for your most sensitive documents.
          </p>
        </div>
        <div>
          <img
            src="./public/images/features/compliance.svg"
            width="100%"
            className="mt-10"
            alt=""
          />
        </div>
      </div>
    </>
  );
}
