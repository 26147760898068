import {useProducts} from './use-products';
import {Button} from '../../ui/buttons/button';
import {Trans} from '../../i18n/trans';
import {ForumIcon} from '../../icons/material/Forum';
import {Navbar} from '../../ui/navigation/navbar/navbar';
import {Link} from 'react-router-dom';
import {Footer} from '../../ui/footer/footer';
import {Fragment, useState} from 'react';
import {UpsellBillingCycle} from './find-best-price';
import {BillingCycleRadio} from './billing-cycle-radio';
import {StaticPageTitle} from '../../seo/static-page-title';
import {PricingTable} from '@common/billing/pricing-table/pricing-table';
import NewFooter from '@common/ui/new-footer/NewFooter';
import PricingCompareTable from './pricing-compare-table';
import {FAQSection} from '@app/pages/Faq';
import {TestimonialSection} from '@app/landing/landing-page';
import Testimonials from '@app/landing/Testimonials';
import {FaCheckCircle, FaRegCheckCircle, FaRegCircle} from 'react-icons/fa';
import ContactCard from '@common/ui/contact-card/ContactCard';
import AddBar from '@app/pages/AddBar';
export function PricingPage() {
  const query = useProducts('pricingPage');
  const [selectedCycle, setSelectedCycle] =
    useState<UpsellBillingCycle>('yearly');
  const [billingCycle, setBillingCycle] = useState<'monthly' | 'yearly'>(
    'monthly',
  );
  return (
    <Fragment>
      <StaticPageTitle>
        <Trans message="Pricing" />
      </StaticPageTitle>
      <div className="container mx-auto ">
        <Navbar
          color="transparent"
          className="flex-shrink-0"
          menuPosition="pricing-table-page"
        />
      </div>
      <AddBar/>

      <div className="container mx-auto px-24">
        <h1 className="mb-30 mt-30 text-center text-3xl font-normal md:mt-60 md:text-4xl md:font-medium lg:text-6xl lg:font-bold">
          {/* <Trans message="Choose the right plan for you" /> */}
          Choose the right plan for you
        </h1>

        <p className="text-center lg:px-[18%]">
          Choose the plan that fits your needs. All plans include essential
          features to get you started, with options to scale as you grow. No
          hidden fees and the flexibility to change anytime.
        </p>

        {/* <BillingCycleRadio selectedCycle={billingCycle} onChange={setBillingCycle} />; */}
        <BillingCycleRadio
          products={query.data?.products}
          selectedCycle={selectedCycle}
          onChange={setSelectedCycle}
          className="mb-40 flex justify-center md:mb-70"
          size="lg"
        />

     <div className='px-[8%]'>
     <PricingTable
          selectedCycle={selectedCycle}
          productLoader="pricingPage"
        />
     </div>
        <div className="mt-60 mb-30 rounded-lg bg-[#00348E] p-6 text-white">
          <div className="flex flex-wrap items-center justify-between gap-50 px-[10%] py-50">
            <h2 className="mb-4 text-center text-3xl font-bold">
              Enjoy it all for free! No Extra Cost
            </h2>
            <div className="grid grid-cols-1 gap-24 text-sm md:grid-cols-2">
              <div className="flex items-center gap-2">
                <FaRegCheckCircle className="text-white" />
                <span>10GB of Secure Storage</span>
              </div>
              <div className="flex items-center gap-2">
                <FaRegCheckCircle className="text-white" />
                <span>Advanced Link Share Controls</span>
              </div>
              <div className="flex items-center gap-2">
                <FaRegCheckCircle className="text-white" />

                <span>Two Factor Authentication (2FA)</span>
              </div>
              <div className="flex items-center gap-2">
                <FaRegCheckCircle className="text-white" />

                <span>Secure & Fast Cloud Servers</span>
              </div>
            </div>
          </div>
        </div>

        <PricingCompareTable />
        <h1 className="mb-30 mt-30 text-center text-3xl font-normal md:mt-60 md:text-4xl md:font-medium lg:text-4xl lg:font-bold">
          Trusted by millions of users globally.
        </h1>

        <Testimonials />
        <FAQSection />
        <ContactCard />
        {/* <ContactSection /> */}
      </div>

      <NewFooter />
      {/* <Footer className="container mx-auto flex-shrink-0 px-24" /> */}
    </Fragment>
  );
}

function ContactSection() {
  return (
    <div className="my-20 p-24 text-center md:my-80">
      <ForumIcon size="xl" className="text-muted" />
      <div className="my-8 md:text-lg">
        <Trans message="Do you have any questions about PRO accounts?" />
      </div>
      <div className="mb-24 mt-20 text-sm md:mt-0 md:text-base">
        <Trans message="Our support team will be happy to assist you." />
      </div>
      <Button variant="flat" color="primary" elementType={Link} to="/contact">
        <Trans message="Contact us" />
      </Button>
    </div>
  );
}
