import React from 'react';
import Rating from './Rating';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

const Testimonials = () => {
  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 5 },
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 3 },
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 2 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
  };

  const TestimonialsData = [
            {
              heading: 'Super fast upload',
              comment:
                "I've been using this file upload service for my business for over a year now, and I can't imagine life without it! It's made sharing large documents with my team effortless and secure.",
              rating: 3,
              author: 'Cravens',
              designation: 'BizBite',
              image: 'assets/img/clients/client-1.png',
            },
            {
              heading: 'Most secure and safe',
              comment:
                'I needed a way to securely share confidential documents with my clients, and this file upload website exceeded my expectations. The password protection feature gives me peace of mind knowing that only authorized individuals can access my files.',
              rating: 5,
              author: 'Kevin',
              designation: 'Team9',
              image: 'assets/img/clients/client-2.png',
            },
            {
              heading: 'Efficiently innovate',
              comment:
                "I've tried several file upload services in the past, but none compare to this one. The interface is intuitive, the upload speeds are lightning-fast, and the customer support team is top-notch.",
              rating: 5,
              author: 'Martin',
              designation: 'CloudCore',
              image: 'assets/img/clients/client-3.png',
            },
            {
              heading: 'Uniquely flexible',
              comment:
                "This file upload platform has completely streamlined my workflow. Whether I'm collaborating with colleagues or sending files to friends and family, it's always my go-to choice.",
              rating: 4,
              author: 'Mark Fleming',
              designation: 'Twitter',
              image: 'assets/img/clients/client-4.png',
            },
            {
              heading: 'Compellingly empower app',
              comment:
                "I run a small photography business, and this website has been a game-changer for sharing proofs and final edits with my clients. It's incredibly convenient and has helped me impress clients with my professionalism.",
              rating: 5,
              author: 'Matt',
              designation: 'MaxC',
              image: 'assets/img/clients/client-5.png',
            },
            {
              heading: 'Holisticly reintermediate',
              comment:
                "I can't believe I used to struggle with unreliable file transfer methods before discovering this website. Now, sending large files to my coworkers is a breeze – no more email attachment limits or USB drives to worry about.",
              rating: 4,
              author: 'Svenson',
              designation: 'BuzzMi',
              image: 'assets/img/clients/client-6.png',
            },
            {
              heading: 'Uniquely mesh flexible',
              comment:
                "I'm not the most tech-savvy person, but this file upload website couldn't be easier to use. I love how straightforward it is to upload and organize my files – it's like having my own personal file manager in the cloud.",
              rating: 4,
              author: 'Grant',
              designation: 'Hostrs',
              image: 'assets/img/clients/client-7.png',
            },
            {
              heading: 'Compellingly empower app',
              comment:
                "I've been recommending this file upload service to all of my friends and colleagues. It's the perfect solution for anyone who needs a secure, efficient way to share files online.",
              rating: 5,
              author: 'Damon',
              designation: 'BestBuy',
              image: 'assets/img/clients/client-8.png',
            },
            {
              heading: 'Holisticly reintermediate',
              comment:
                "I've tried other file upload platforms in the past, but they always seemed to have limitations that frustrated me. This website, however, offers everything I need and more – it's truly a game-changer for my productivity.",
              rating: 5,
              author: 'Kowarsky',
              designation: 'Targget',
              image: 'assets/img/clients/client-9.png',
            },
          ];

  // Custom button group for navigation
  const CustomButtonGroup = ({ next, previous }: { next?: () => void; previous?: () => void }) => {
    return (
      <div className="justify-between items-center mt-4 hidden lg:flex">
        <button
          className="absolute left-[-50px] top-1/2 transform -translate-y-1/2 bg-gray-200 hover:bg-gray-400 text-black p-16 rounded-full shadow-lg"
          onClick={previous}
        >
      <IoIosArrowBack />
        </button>
        <button
          className="absolute right-[-50px] top-1/2 transform -translate-y-1/2 bg-gray-200 hover:bg-gray-400 text-black p-16 rounded-full shadow-lg"
          onClick={next}
        >
         <IoIosArrowForward />
        </button>
      </div>
    );
  };

  return (
    <div className="relative mt-10">
      <Carousel
        responsive={responsive}
        showDots={true}
        customTransition="all .5"
        transitionDuration={500}
        autoPlay={true}
        arrows={false}
        autoPlaySpeed={2000}
        dotListClass="custom-dot-list-style"
        renderButtonGroupOutside={true}
        customButtonGroup={<CustomButtonGroup />}
      >
        {TestimonialsData.map((val, ind) => (
          <div key={ind} className="mx-10 mb-30 rounded-xl border bg-white">
            <div className="pl-20 pr-20 pt-20">
              <div className="review-body">
                <Rating rating={val.rating} />
                <h5 className="text-lg font-bold">{val.heading}</h5>
                <p className="line-clamp-4">{val.comment}</p>
              </div>
            </div>
            <div className="review-author d-flex align-items-center mt-20 rounded-bl-xl rounded-br-xl rounded-tr-[30px] bg-[#F1F6FF] dark:bg-[#3B82F6]">
              <div className="py-20 pl-20">
                <div className="author-avatar flex items-center gap-3">
                  <div className="author-avatar">
                    <img
                      src={val.image}
                      width="64"
                      alt="author"
                      className="mr-3 rounded-full shadow-sm"
                    />
                  </div>
                  <div className="review-info">
                    <h6 className="mb-0">{val.author}</h6>
                    <span>{val.designation}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Testimonials;












// import React from 'react';
// import Slider from 'react-slick';
// import Rating from './Rating';
// import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// const Testimonials = () => {
//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 2000,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 2,
//         },
//       },
//       {
//         breakpoint: 768,
//         settings: {
//           slidesToShow: 1,
//         },
//       },
//     ],
//     nextArrow: <CustomNextArrow />, 
//     prevArrow: <CustomPrevArrow />,
//   };


//   const TestimonialsData = [
//         {
//           heading: 'Super fast upload',
//           comment:
//             "I've been using this file upload service for my business for over a year now, and I can't imagine life without it! It's made sharing large documents with my team effortless and secure.",
//           rating: 3,
//           author: 'Cravens',
//           designation: 'BizBite',
//           image: 'assets/img/clients/client-1.png',
//         },
//         {
//           heading: 'Most secure and safe',
//           comment:
//             'I needed a way to securely share confidential documents with my clients, and this file upload website exceeded my expectations. The password protection feature gives me peace of mind knowing that only authorized individuals can access my files.',
//           rating: 5,
//           author: 'Kevin',
//           designation: 'Team9',
//           image: 'assets/img/clients/client-2.png',
//         },
//         {
//           heading: 'Efficiently innovate',
//           comment:
//             "I've tried several file upload services in the past, but none compare to this one. The interface is intuitive, the upload speeds are lightning-fast, and the customer support team is top-notch.",
//           rating: 5,
//           author: 'Martin',
//           designation: 'CloudCore',
//           image: 'assets/img/clients/client-3.png',
//         },
//         {
//           heading: 'Uniquely flexible',
//           comment:
//             "This file upload platform has completely streamlined my workflow. Whether I'm collaborating with colleagues or sending files to friends and family, it's always my go-to choice.",
//           rating: 4,
//           author: 'Mark Fleming',
//           designation: 'Twitter',
//           image: 'assets/img/clients/client-4.png',
//         },
//         {
//           heading: 'Compellingly empower app',
//           comment:
//             "I run a small photography business, and this website has been a game-changer for sharing proofs and final edits with my clients. It's incredibly convenient and has helped me impress clients with my professionalism.",
//           rating: 5,
//           author: 'Matt',
//           designation: 'MaxC',
//           image: 'assets/img/clients/client-5.png',
//         },
//         {
//           heading: 'Holisticly reintermediate',
//           comment:
//             "I can't believe I used to struggle with unreliable file transfer methods before discovering this website. Now, sending large files to my coworkers is a breeze – no more email attachment limits or USB drives to worry about.",
//           rating: 4,
//           author: 'Svenson',
//           designation: 'BuzzMi',
//           image: 'assets/img/clients/client-6.png',
//         },
//         {
//           heading: 'Uniquely mesh flexible',
//           comment:
//             "I'm not the most tech-savvy person, but this file upload website couldn't be easier to use. I love how straightforward it is to upload and organize my files – it's like having my own personal file manager in the cloud.",
//           rating: 4,
//           author: 'Grant',
//           designation: 'Hostrs',
//           image: 'assets/img/clients/client-7.png',
//         },
//         {
//           heading: 'Compellingly empower app',
//           comment:
//             "I've been recommending this file upload service to all of my friends and colleagues. It's the perfect solution for anyone who needs a secure, efficient way to share files online.",
//           rating: 5,
//           author: 'Damon',
//           designation: 'BestBuy',
//           image: 'assets/img/clients/client-8.png',
//         },
//         {
//           heading: 'Holisticly reintermediate',
//           comment:
//             "I've tried other file upload platforms in the past, but they always seemed to have limitations that frustrated me. This website, however, offers everything I need and more – it's truly a game-changer for my productivity.",
//           rating: 5,
//           author: 'Kowarsky',
//           designation: 'Targget',
//           image: 'assets/img/clients/client-9.png',
//         },
//       ];
//   function CustomPrevArrow(props) {
//     const { onClick } = props;
//     return (
//       <button
//         onClick={onClick}
//         className="absolute left-[-50px] top-[40%] z-10 flex h-10 w-10 items-center justify-center rounded-full bg-white shadow-lg hover:bg-gray-200"
//       >
//         <FaChevronLeft size={20} />
//       </button>
//     );
//   }

//   function CustomNextArrow(props) {
//     const { onClick } = props;
//     return (
//       <button
//         onClick={onClick}
//         className="absolute right-[-50px] top-[40%] z-10 flex h-10 w-10 items-center justify-center rounded-full bg-black text-white shadow-lg hover:bg-gray-200"
//       >
//         <FaChevronRight size={40} />
//       </button>
//     );
//   }

//   return (
//     <div className="mt-30 relative">
//       <Slider {...settings}>
//         {TestimonialsData.map((val, ind) => (
//        <div className='gap-30 space-x-5'>
//            <div key={ind} className=" mb-30 rounded-xl border bg-white ">
//             <div className="p-5">
//               <Rating rating={val.rating} />
//               <h5 className="text-lg font-bold">{val.heading}</h5>
//               <p className="line-clamp-4">{val.comment}</p>
//             </div>
//             <div className="p-5 bg-[#F1F6FF] dark:bg-[#3B82F6] rounded-bl-xl rounded-br-xl rounded-tr-[30px]">
//               <div className="flex items-center gap-3">
//                 <img src={val.image} width="64" alt="author" className="rounded-full shadow-sm" />
//                 <div>
//                   <h6 className="mb-0">{val.author}</h6>
//                   <span>{val.designation}</span>
//                 </div>
//               </div>
//             </div>
//           </div>
//        </div>
//         ))}
//       </Slider>
//     </div>
//   );
// };

// export default Testimonials;