import React from 'react';
import {MdStarHalf} from 'react-icons/md';
import {MdOutlineStarBorderPurple500, MdStarRate} from 'react-icons/md';
interface RatingProps {
  rating: number;
  maxStars?: number;
}

const Rating: React.FC<RatingProps> = ({rating, maxStars = 5}) => {
  return (
    <div className="flex items-center">
      {[...Array(maxStars)].map((_, index) => {
        const starValue = index + 1;

        return (
          <span key={index} className="ms-1 text-primary">
            {rating >= starValue ? (
              <MdStarRate />
            ) : rating >= starValue - 0.5 ? (
              <MdStarHalf />
            ) : (
              <MdOutlineStarBorderPurple500 />
            )}
          </span>
        );
      })}
    </div>
  );
};

export default Rating;
