import { Navbar } from '@common/ui/navigation/navbar/navbar';
import NewFooter from '@common/ui/new-footer/NewFooter';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GrDownload } from 'react-icons/gr';

const SecureMessageLink = () => {
  // const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  // console.log('id--->', id);

  const [message, setMessage] = useState<any>(null);
  // Fetch message from the API endpoint
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://mydocuploads.com/api/get/message/${id}`,
          {},
        );
        console.log('response', response);

        setMessage(response.data); // Set the fetched message to state
      } catch (error) {
        console.error('Error fetching message:', error);
        // const errorMessage = (error as { response?: { data?: { error?: string } } })?.response?.data?.error;
        // if (errorMessage !== undefined) {
        //   toast.error(`${errorMessage}`);
        // }
      }
    };

    fetchData(); // Call the fetch function
  }, [id]);

  // const getFileTypeIcon = (fileUrl: string) => {
  //   if (!fileUrl) return null;
  const getFileTypeIcon = (fileUrl: string): string | undefined => {
    if (!fileUrl) return undefined;

    const lowerCaseFileUrl = fileUrl.toLowerCase();

    if (
      lowerCaseFileUrl.endsWith('.zip') ||
      lowerCaseFileUrl.endsWith('.rar')
    ) {
      return './public/images/message/winrar.svg';
    }
    if (lowerCaseFileUrl.endsWith('.pdf')) {
      return './public/images/message/pdff.svg';
    }
    if (lowerCaseFileUrl.endsWith('.docs')) {
      return './public/images/message/docs.svg';
    }
    if (lowerCaseFileUrl.endsWith('.xlsx')) {
      return './public/images/message/xlsx.svg';
    }
    if (
      lowerCaseFileUrl.endsWith('.jpg') ||
      lowerCaseFileUrl.endsWith('.jpeg') ||
      lowerCaseFileUrl.endsWith('.png') ||
      lowerCaseFileUrl.endsWith('.gif') ||
      lowerCaseFileUrl.endsWith('.svg') ||
      lowerCaseFileUrl.endsWith('.webp')
    ) {
      return './public/images/fileupload.svg';
    }
    return './public/images/message/docs.svg';
  };

  const handleDownload = (fileUrl: string | undefined) => {
    if (!fileUrl) return;

    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = (extractFilename(fileUrl) as string) || 'downloaded-file';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const extractFilename = (url: string) => {
    return url.split('/').pop();
  };

  const handleDownloadAll = () => {
    const files = [
      message?.data?.file1,
      message?.data?.file2,
      message?.data?.file3,
    ].filter(Boolean);

    if (files.length === 0) {
      toast.error('No files available for download.');
      return;
    }

    files.forEach(fileUrl => {
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = extractFilename(fileUrl) || 'downloaded-file';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });

    toast.success('Downloading all files...');
  };

  const truncateFilename = (filename: string): string => {
    if (!filename) return '';

    const lastDotIndex = filename.lastIndexOf('.');
    if (lastDotIndex === -1) return filename;

    const namePart = filename.substring(0, lastDotIndex);
    const extension = filename.substring(lastDotIndex);

    return namePart.length > 8
      ? `${namePart.substring(0, 8)}..${extension}`
      : filename;
  };

  return (
    <>
      {/* <div className='container mx-auto p-4'>
        <div className="flex justify-center">
          <div className="w-full max-w-3xl">
            <div className="p-4 bg-img" >
              <h1 className="heading">Shared Link</h1>
              <h1 className="heading">Messages Easily</h1>
              <div className='text-area'>
                <p>Secret is saved! Here is the link for your Secret Message.</p>
                <div className='bg-[#90c8f9] p-4 rounded'>
                  <p>sasasasaas</p>
                </div>
                <div className='flex gap-[10px]'>
                  <div className='bg-[#7e7f9b] rounded-full h-[30px] w-[30px] flex justify-center align-center'>
                    <FaLink className='text-white mt-5' />
                  </div>
                  <WhatsappIcon size={32} round={true} />
                  <LinkedinIcon size={32} round={true} />
                  <TwitterIcon size={32} round={true} />


                </div>
              </div>

            </div>
          </div>
        </div>
      </div> */}
      <>
        {!message && (
          <>
            <ToastContainer />
          </>
        )}

        <Navbar
          color="transparent"
          className="flex-shrink-0"
          menuPosition="homepage-navbar"
        />
        {!message && (
          <>
            <center className="my-50 py-50">
              <h1>Message View time completed</h1>
            </center>
          </>
        )}
        {message && (
          <div className="container mx-auto my-50 p-4">
            <div className="flex justify-center">
              <div className="relative w-full max-w-4xl rounded-xl border-2 p-20">
                <div className="rounded-xl bg-primary dark:bg-[#3B82F6] p-20">
                  <div className="absolute right-0 w-full">
                    <div className="bg-img  z-10">
                      {/* <h1 className="heading">Send Encrypted Messages Easily</h1>
                  <textarea
                    className="form-control text-area mt-3"
                    rows={5}
                    placeholder="Type your secret message here..."
                    {...formik.getFieldProps('message')}
                  ></textarea> */}
                    </div>
                  </div>
                  <div className="relative z-50 mt-16">
                    <h1 className="heading">Here is your Secret Message:</h1>
                    {/* <h1 className="heading">Messages Easily</h1> */}
                    <textarea
                      className="form-control text-area z-50 mt-3 min-h-[150px] dark:text-black"
                      rows={5}
                      readOnly
                    >
                      {message?.data?.secret}
                    </textarea>
                  </div>
                </div>

                <div className="pt-20">
                  <h2 className="mt-10 font-bold">Attached Secret Files:</h2>
                  <div className="mt-10 flex w-full flex-wrap gap-10 p-2">
                    {[
                      message?.data?.file1,
                      message?.data?.file2,
                      message?.data?.file3,
                    ].map(
                      (file, index) =>
                        file && (
                          <div
                            key={index}
                            className="mt-10 flex items-center gap-16 rounded-lg border-2 p-10"
                          >
                            <img
                              src={getFileTypeIcon(file)}
                              alt="File Icon"
                              className="h-30 w-30 object-cover"
                            />

                            <p className="text-xs font-semibold">
                              {truncateFilename(extractFilename(file) || '') ||
                                `File ${index + 1}`}
                            </p>

                            <GrDownload
                              className="cursor-pointer text-lg text-primary"
                              onClick={() => handleDownload(file)}
                            />

                            {/* <a
                            className="download-file-btn"
                            href={file}
                            download={extractFilename(file)}
                          >
                            Download File {index + 1}
                          </a> */}
                          </div>
                        ),
                    )}
                  </div>
                  <div className="flex flex-wrap items-center justify-between">
                    <p className="expire-text mt-5 dark:text-white">
                      Only {message?.data?.remaninig_view} views are remaning and
                      its expire after {message?.data?.remaning_hours}{' '}
                    </p>

                    <div className="mt-5 text-center">
                      <button
                        onClick={handleDownloadAll}
                        className="download-file-btn"
                      >
                        Download All Files
                      </button>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        )}
        <NewFooter />
      </>
    </>
  );
};

export default SecureMessageLink;
