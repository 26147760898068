import {useState} from 'react';
import clsx from 'clsx';
import {UpsellBillingCycle} from './find-best-price';
import {RadioGroupProps} from '../../ui/forms/radio-group/radio-group';
import {UpsellLabel} from './upsell-label';
import {Product} from '../product';

interface BillingCycleRadioProps extends Omit<RadioGroupProps, 'children'> {
  selectedCycle: UpsellBillingCycle;
  onChange: (value: UpsellBillingCycle) => void;
  products?: Product[];
}
export function BillingCycleRadio({
  selectedCycle,
  onChange,
  products,
  ...radioGroupProps
}: BillingCycleRadioProps) {
  return (
    <div className="mb-20 mt-30 flex flex-col items-center">
      <div className="z-50 flex rounded-full border-2 bg-[#E1E1E1] dark:bg-black p-1">
        <button
          className={clsx(
            'rounded-full px-20 py-6 font-semibold transition',
            selectedCycle === 'monthly'
              ? 'bg-[#ffffff] text-black shadow'
              : 'text-gray-500',
          )}
          onClick={() => onChange('monthly')}
        >
          Monthly
        </button>
        <button
          className={clsx(
            'rounded-full px-20 py-6 font-semibold transition',
            selectedCycle === 'yearly'
              ? 'bg-[#ffffff] text-black shadow'
              : 'text-gray-500',
          )}
          onClick={() => onChange('yearly')}
        >
          Annual
        </button>
      </div>

      <p
        className={clsx(
          'mt-2 text-sm font-semibold text-primary transition-opacity duration-300 dark:text-white',
          selectedCycle === 'yearly' ? 'opacity-100' : 'opacity-0',
        )}
      >
        Save up to 20%
      </p>
    </div>
  );
}



// import {Radio} from '../../ui/forms/radio-group/radio';
// import {UpsellBillingCycle} from './find-best-price';
// import {Trans} from '../../i18n/trans';
// import {
//   RadioGroup,
//   RadioGroupProps,
// } from '../../ui/forms/radio-group/radio-group';
// import {UpsellLabel} from './upsell-label';
// import {Product} from '../product';

// interface BillingCycleRadioProps extends Omit<RadioGroupProps, 'children'> {
//   selectedCycle: UpsellBillingCycle;
//   onChange: (value: UpsellBillingCycle) => void;
//   products?: Product[];
// }
// export function BillingCycleRadio({
//   selectedCycle,
//   onChange,
//   products,
//   ...radioGroupProps
// }: BillingCycleRadioProps) {
//   return (
//     <RadioGroup {...radioGroupProps}>
//       <Radio
//         value="yearly"
//         checked={selectedCycle === 'yearly'}
//         onChange={e => {
//           onChange(e.target.value as UpsellBillingCycle);
//         }}
//       >
//         <Trans message="Annual" />
//         <UpsellLabel products={products} />
//       </Radio>
//       <Radio
//         value="monthly"
//         checked={selectedCycle === 'monthly'}
//         onChange={e => {
//           onChange(e.target.value as UpsellBillingCycle);
//         }}
//       >
//         <Trans message="Monthly" />
//       </Radio>
//     </RadioGroup>
//   );
// }
