import { Navbar } from '@common/ui/navigation/navbar/navbar'
import React from 'react'
import AddBar from '../AddBar'
import NewFooter from '@common/ui/new-footer/NewFooter'
import EverythingCardComp from './components/EverythingCardComp'
import ContactCard from '@common/ui/contact-card/ContactCard'

const TeamCollaboration = () => {
    return (
        <>
            <div className="container mx-auto">
                <Navbar
                    color="transparent"
                    className="flex-shrink-0"
                    menuPosition="homepage-navbar"
                />
            </div>
            <AddBar />
            <TeamCollaborationPage />
            <NewFooter />
        </>
    )
}

export default TeamCollaboration

function TeamCollaborationPage() {
    const everyThingData = [
        {
            title: '10GB of Free Secure Storage',
            desc: 'Start organizing and sharing your files instantly.'
        },
        {
            title: 'User-Friendly Interface',
            desc: 'No complicated software, just easy file management.'
        },
        {
            title: 'Scale as You Grow',
            desc: 'Upgrade storage and features as your business expands.'
        },
        {
            title: 'Create Dedicated Workspaces',
            desc: 'Set up separate spaces for teams, departments, or projects.'
        },
        {
            title: 'Centralized File Access',
            desc: 'Keep all your project documents in one place for seamless collaboration.'
        },
    ]
    return (
        <>

            <HeroSection />
            <div className="container mx-auto">
                <div className="grid grid-col-1 justify-center">
                    <h2 className="text-gray-900 text-center text-5xl font-bold mt-90 mb-16">
                        Built for   <span className="text-primary">SMBs & Remote Teams</span>
                    </h2>
                    <p className='text-base font-normal text-center max-w-4xl'>Designed for growing businesses and distributed teams, MyDocUploads ensures secure, scalable, and hassle-free
                        collaboration, without expensive IT setup.</p>
                </div>
                {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-22 mt-52 justify-center" >
                    {everyThingData.map((data, index) => (
                        <EverythingCardComp title={data.title} desc={data.desc} key={index} />
                    ))}
                </div> */}
                <div className="md:flex md:flex-wrap justify-center gap-22 mt-52" >
                    {everyThingData.map((data, index) => (
                        <EverythingCardComp title={data.title} desc={data.desc} key={index} />
                    ))}
                </div>
                <div className="grid lg:grid-cols-2 gap-20 lg:mt-32 items-center">
                    <div className='mt-32'>
                        <h2 className="text-start dark:text-white text-5xl font-bold mt-20 lg:mt-60">
                            Share Files with    <span className="text-primary">Teammates or Clients</span>
                        </h2>
                        <p className='text-base font-normal text-[#303030B2] dark:text-white tracking-tighter mt-32'>Collaboration shouldn’t be complicated. With MyDocUploads Team Workspaces, you can securely share files, organize projects, and work seamlessly with your team or clients—all in one centralized platform.</p>

                        <div className='mt-32'>
                            <div className='flex xl:items-center gap-5 mb-5'>
                                <img src="./public/images/about-us/correct-icon.png" alt="" />
                                <p className='text-sm text-[#303030B2] dark:text-white font-normal'><b>No more email attachments</b></p>
                            </div>
                            <div className='flex xl:items-center gap-5 mb-5'>
                                <img src="./public/images/about-us/correct-icon.png" alt="" />
                                <p className='text-sm text-[#303030B2] dark:text-white font-normal'><b>No more scattered files</b></p>
                            </div>
                            <div className='flex xl:items-center gap-5 mb-5'>
                                <img src="./public/images/about-us/correct-icon.png" alt="" />
                                <p className='text-sm text-[#303030B2] dark:text-white font-normal'><b>Just seamless, secure, and organized teamwork</b></p>
                            </div>

                        </div>
                    </div>
                    <div className='mt-32 flex justify-center'>
                        <img src="./public/images/solutions/team-1.svg" alt="" />
                    </div>
                </div>
                <div className="grid lg:grid-cols-2 gap-20 mt-16 lg:mt-32 items-center">
                    <div className='mt-32 flex justify-center'>
                        <img src="./public/images/solutions/team-2.svg" alt="" />
                    </div>
                    <div className='mt-32'>
                        <h2 className="text-start dark:text-white text-5xl font-bold mt-20 lg:mt-60">
                            Simplify <span className="text-primary">File Sharing & Collaboration</span>
                        </h2>
                        <p className='text-base font-normal text-[#303030B2] dark:text-white tracking-tighter mt-32'>Whether you're a small business, a remote team, or working with clients, MyDocUploads makes it easy to share, manage, and collaborate on files in a structured way.</p>
                        <div className='mt-32'>
                            <div className='flex xl:items-center gap-5 mb-5'>
                                <img src="./public/images/about-us/correct-icon.png" alt="" />
                                <p className='text-sm text-[#303030B2] dark:text-white font-normal'><b>Create Separate Workspaces</b>   – Set up dedicated folders for different teams, departments, or projects.</p>
                            </div>
                            <div className='flex xl:items-center gap-5 mb-5'>
                                <img src="./public/images/about-us/correct-icon.png" alt="" />
                                <p className='text-sm text-[#303030B2] dark:text-white font-normal'><b>Granular Access Controls </b>  – Assign view, edit, or download permissions to ensure secure file sharing.</p>
                            </div>
                            <div className='flex xl:items-center gap-5 mb-5'>
                                <img src="./public/images/about-us/correct-icon.png" alt="" />
                                <p className='text-sm text-[#303030B2] dark:text-white font-normal'><b>Centralized File Storage </b> – Keep all your project documents, contracts, reports, and files in one place.</p>
                            </div>
                            <div className='flex xl:items-center gap-5 mb-5'>
                                <img src="./public/images/about-us/correct-icon.png" alt="" />
                                <p className='text-sm text-[#303030B2] dark:text-white font-normal'><b>Secure Client Access  </b> – Share files with clients, vendors, or stakeholders without compromising security.</p>
                            </div>
                            <div className='flex xl:items-center gap-5 mb-5'>
                                <img src="./public/images/about-us/correct-icon.png" alt="" />
                                <p className='text-sm text-[#303030B2] dark:text-white font-normal'><b>Cloud-Based & Remote-Friendly  </b> – Access your files anytime, anywhere on any device.</p>
                            </div>

                        </div>
                    </div>

                </div>

                <ContactCard />
            </div>


        </>
    );
}
function HeroSection() {
    return (
        <div className=" bg-[#1698D9]  px-6 py-50 text-white md:pl-24">
            <div className="container mx-auto grid items-center gap-20 md:grid-cols-2">
                {/* Left Section - Text Content */}
                <div className="text-center md:text-left">
                    <h1 className="text-3xl font-bold leading-tight md:text-5xl">
                        Simplify Collaboration with Team Workspaces
                    </h1>
                    <p className="text-base font-semibold text-gray-200 dark:text-white mt-32">
                        Easily share files with your team members, departments, and clients with a secure document workspace.
                    </p>
                    <div className="mt-32 flex justify-center gap-20  md:justify-start">
                        <button className=" !bg-[#F67A3C] font-semibold text-white text-sm px-32 py-12 w-fit h-fit rounded-full">
                            {' '}
                            Get Started
                        </button>

                        <button className="  text-white text-sm px-22 py-10 w-fit h-fit rounded-full border border-white">
                            {' '}
                            Compare Plans
                        </button>
                    </div>
                </div>

                {/* Right Section - Image */}
                <div className="flex justify-center">
                    <img
                        src="./public/images/solutions/hero-team-collaboration.svg"
                        alt="File Management"
                        className="w-full"
                    />
                </div>
            </div>
        </div>
    );
}