import React from 'react'

const ContactCard = ({ title, subTitle, className }: any) => {
  return (
    <>
      <div className={`my-100 rounded-2xl bg-gradient-to-r from-[#34A4FF] to-primary px-6 py-40 text-center text-white dark:bg-gradient-to-r from-[#0244C2] to-[#34A4FF] ${className}`}>
        <h2 className="text-3xl font-bold md:text-4xl">
          {title ? title : "Start with 10GB of Secure Storage Space"}
        </h2>
        <p className=" text-lg opacity-90 mt-10">
          {subTitle ? subTitle : "Get your team set up in minutes! Register for a free account and Create your first Team Workspace today."}
        </p>

        <button className="mt-6 rounded-full bg-[#ffffff] px-16 py-14 font-semibold text-[#F67A3C] shadow-md transition duration-300 hover:bg-primary hover:text-white ">
          Get Started for Free
        </button>

        <p className="mt-6 text-sm italic opacity-80">
          with MyDocUploads.com
        </p>
      </div>


    </>
  )
}

export default ContactCard